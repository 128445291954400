import { Route, Routes, Navigate, Link } from "react-router-dom";
import UserPortal from "../pages/userportal/UserPortal";
import React from "react";
import TopNav from "../pages/userportal/TopNav/TopNav";
import HistoryVideos from "../pages/userportal/HistoryVideos";
import UserEditProtal from "../pages/userportal/AccountEditProtal";
import axios from "axios";
import { CircularProgress, Box } from "@mui/material";

import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import LoginPage from "../pages/userportal/LoginPage";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useNavigate } from "react-router-dom";
import NewUserPortal from "../pages/userportal/NewUserPortal";
import Profile from "../pages/userportal/Profile";
import PrivateRoute from "./PrivateRoute";

// const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
//   const user = useSelector((state: RootState) => state.auth.user);
//   const isVerified = user?.isVerified;

//   if (!user || !isVerified) {
//     return <Navigate to="/" />;
//   }

//   return <>{children}</>;
// };

export default function PortalRouter() {
  const userEmail = Cookies.get("userEmail");
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userName, setUserName] = useState("");
  const [program, setProgram] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user); // Replace "auth" with your Redux slice name
  console.log("user", id);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading process, e.g., fetching data
    // For demonstration, we're using a timeout to simulate a fetch
    const timer = setTimeout(() => {
      setIsLoading(false); // Hide spinner after loading is done
    }, 2000); // Adjust time as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const getUserId = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/user/getuser/${user.email}`
        );
        if (response.status === 200) {
          const fullName = response.data?.user?.fullName;
          const _id = response?.data?.user._id;
          const program = response?.data?.user?.selectedProgram;
          const isVerified = response?.data?.user?.isVerified;
          setUserName(fullName);
          setId(_id);
          localStorage.setItem("id", _id);
          setProgram(program);
          setIsVerified(isVerified);
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    };
    getUserId();
  }, [user.email]);
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Full screen height
          width: "100vw", // Full screen width
          position: "fixed", // Overlay the entire screen
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: screen dimming
          zIndex: 9999, // Ensure it's above everything
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route
            path="/"
            element={
              <NewUserPortal
                userName={userName}
                iduser={id}
                propProgram={program}
                email={user?.email}
              />
            }
          />
          <Route
            path="/historyvideos"
            element={<HistoryVideos iduser={id} />}
          />
          <Route path="/usereditprotal" element={<UserEditProtal />} />
          <Route
            path="/profile"
            element={
              <Profile iduser={id} userName={userName} email={user?.email} />
            }
          />
        </Route>
        <Route path="/loginpage" element={<LoginPage iduser={id} />} />
        <Route
          path="*"
          element={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1>אימות האימייל נכשל</h1>
              <h3>אנא וודא שהאימייל שלך אומת</h3>
              <h3>או צור קשר עם מנהל האתר </h3>
            </div>
          }
        />
      </Routes>
    </>
  );
}
