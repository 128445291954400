import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  ThemeProvider,
  Button,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { LanguagesData, languages } from "../../data/languages";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { UploadButton } from "@bytescale/upload-widget-react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import MediaFileItem from "./MediaFileItem";
import createTheme from "@mui/material/styles/createTheme";
import { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router";
import axios, { AxiosResponse } from "axios";
import IframePage from "../singlePaymentCall/IframePage";
import emailjs from "emailjs-com";
import NavbarPortal from "./NavbarPortal";
type UserPageProps = {
  userName: string;
  iduser: string;
  propProgram: string;
  email: string;
};

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

const theme = createTheme({
  direction: "rtl",
});

const options = {
  apiKey: "public_W142iUY8Pn4CwZvD2zRRt3w6ni7V",
  mimeTypes: ["video/*"],
  maxFileCount: 1,
  showRemoveButton: false,
  showFinishButton: true,
  layout: "modal",
  locale: {
    uploadFileBtn: "העלאת סרטון",
    orDragDropFile: "או גרור ושחרר קובץ",
    submitBtnLoading: "טוען...",
    continueBtn: "המשך",
    finishBtn: "העלאת סרטון לתרגום",
    cropBtn: "חיתוך",
    rotateBtn: "סובב",
    scaleBtn: "גודל",
    removeBtn: "הסר",
    cancelPreviewBtn: "ביטול",
    cancelBtn: "ביטול",
    errorMessages: {
      invalidFileType: "סוג קובץ לא חוקי",
      invalidFileSize: "גודל קובץ גדול מדי",
      invalidFileExtension: "סיומת קובץ לא חוקית",
      invalidMaxFileCount: "מספר קבצים מקסימלי חריג",
      invalidMinResolution: "רזולוציה נמוכה מדי",
      invalidMaxResolution: "רזולוציה גבוהה מדי",
      invalidMinFileSize: "קובץ קטן מדי",
      invalidMaxFileSize: "קובץ גדול מדי",
      invalidImageFormat: "פורמט תמונה לא חוקי",
      invalidVideoFormat: "פורמט וידאו לא חוקי",
      invalidAudioFormat: "פורמט אודיו לא חוקי",
      invalidFileFormat: "פורמט קובץ לא חוקי",
      invalidMimeType: "סוג MIME לא חוקי",
      invalidMinDuration: "משך קצר מדי",
      invalidMaxDuration: "משך ארוך מדי",
      invalidResolution: "רזולוציה לא חוקית",
    },
  },
};

const NewUserPortal: React.FC<UserPageProps> = ({
  userName,
  iduser,
  propProgram,
  email,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [videoPreview, setVideoPreview] = useState(false);
  const [originalLanguage, setOriginalLanguage] = useState("עברית");
  const [disabled, setDisabled] = useState(true);
  const [translatedLanguage, setTranslatedLanguage] = useState("");
  const [uploadedFileUrls, setUploadedFileUrls] = useState<string[]>([]);
  const [showUploadMessage, setShowUploadMessage] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguagesData>();
  const [videoList, setVideoList] = useState<any[]>([]);
  const [getDetailsVideos, setGetDetailsVideos] = useState<any>();
  const [showUploadButton, setShowUploadButton] = useState(false); // Set initial state to false
  const [openDialog, setOpenDialog] = useState(false);
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const [selectedLanguagePlane, setSelectedLanguagePlane] = useState<
    LanguagesData[]
  >([]);

  const navigate = useNavigate();

  const useResponsiveStyles = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { isMobile };
  };

  const { isMobile } = useResponsiveStyles();

  const handleTranslatedLanguageChange = (event: SelectChangeEvent<string>) => {
    setTranslatedLanguage(event.target.value as string);
    setDisabled(false);
  };

  const backToUserPortal = () => {
    setVideoPreview(false);
  };

  const handleFileUploadUpdate = (uploadedFiles: { fileUrl: string }[]) => {
    console.log(uploadedFiles.map((x) => x.fileUrl).join("\n"));
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleFileUploadComplete = (files: { fileUrl: string }[]) => {
    if (!files || files.length === 0) {
      alert("לא נבחר קובץ להעלאה");
      return;
    }
    const urls = files.map((x) => x.fileUrl);
    setUploadedFileUrls(urls);
    setShowUploadMessage(true);

    const templateParams = {
      to_name: "Recipient Name",
      from_name: email,
      uri: urls[0],
      language: translatedLanguage,
    };

    emailjs
      .send(
        "service_cs9pleq",
        "template_qsdicox",
        templateParams,
        "cdfqrlv42Baz6U-jk"
      )
      .then(
        (response) => {
          console.log(
            "Email successfully sent!",
            response.status,
            response.text
          );
          alert("הסרטון נשלח לתרגום, הסרטון יועלה לאחר התרגום וישלח למייל שלך");
        },
        (err) => {
          console.error("Failed to send email. Error: ", err);
          alert("הסרטון לא נשלח, נסה שנית או צור איתנו קשר");
        }
      );

    setTimeout(() => {
      setShowUploadMessage(false);
    }, 5000);
  };

  useEffect(() => {
    const languageCategories: {
      [key: string]: LanguagesData[];
    } = {
      basic: languages.filter((language) => language?.plan === "basic"),
      silver: languages.filter(
        (language) => language.plan === "silver" || language?.plan === "basic"
      ),
      gold: languages.filter(
        (language) =>
          language.plan === "gold" ||
          language.plan === "silver" ||
          language.plan === "basic"
      ),
    };
    const filteredLanguages = propProgram
      ? languageCategories[propProgram]
      : [];
    setSelectedLanguagePlane(filteredLanguages);
  }, [propProgram]);

  useEffect(() => {
    const getVideoListOfUser = async () => {
      const baseURL = process.env.REACT_APP_API_URL;
      try {
        const response = await fetch(`${baseURL}/user/video/${iduser}`);
        const data = await response.json();
        setVideoList(data.videoUploads);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    const getDetails = async () => {
      const baseURL = process.env.REACT_APP_API_URL;
      const res: AxiosResponse<any> = await axios.get(
        `${baseURL}/user/videos-uploaded/${email}`
      );

      if (res.data) {
        setGetDetailsVideos(res.data);

        const videoData = await getVideoDuration();
        if (
          res.data.numberOfVideosUploaded >= 1 &&
          propProgram === "basic" &&
          videoData === false
        ) {
          setShowUploadButton(false);
        } else {
          setShowUploadButton(true); // Update state based on response
        }
      }
    };

    const getVideoDuration = async () => {
      const baseURL = process.env.REACT_APP_API_URL;
      const res: AxiosResponse<any> = await axios.get(
        `${baseURL}/user/${iduser}/can-upload-video`
      );
      if (res.data) {
        return res.data.canUploadVideo;
      }
    };

    Promise.all([getVideoListOfUser(), getDetails()]).then(() => {
      setLoading(false); // End loading after fetching data
    });
  }, [email, iduser, propProgram]);

  const handlePurchaseVideo = async () => {
    setLoading(true); // Start loading
    try {
      const paymentDetails = {
        userEmail: email,
        price: 24.9,
        fullName: userName,
      };
      const baseURL = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${baseURL}/pay/single-payment`,
        paymentDetails
      );
      if (response.status === 200) {
        handleOpenDialog();
        setUrl(response.data);
      } else {
        alert("Payment failed");
      }
    } catch (error) {
      console.error("Payment error:", error);
      alert("Payment error");
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <NavbarPortal userName={userName} />
        <div className="main_portal">
          {videoPreview ? (
            <Grid container>
              <Grid item xs={12} sm={6} md={12}>
                <Box
                  style={{
                    padding: "20px",
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    backgroundColor: "#292A30",
                  }}
                >
                  <Box
                    style={{
                      borderBottom: "1px solid grey",
                      borderBottomWidth: 1,
                      padding: "20px 0",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        padding: 0,
                        minWidth: "auto",
                      }}
                      onClick={backToUserPortal}
                    >
                      <ArrowForwardIcon
                        style={{
                          color: "white",
                          fontSize: "2rem",
                        }}
                      />
                    </Button>
                    <Typography variant="h4">בחר שפה </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "20px",
                      width: "100%",
                    }}
                  >
                    <form>
                      <FormControl
                        fullWidth
                        variant="filled"
                        sx={{
                          marginBottom: "1rem",
                          borderBottom: "1px solid grey",
                        }}
                      >
                        <Typography variant="h6">שפה מקורית</Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            marginBottom: "1rem",
                          }}
                        >
                          בחר את השפה המקורית של הסרטון
                        </Typography>
                        <FormControl
                          fullWidth
                          variant="filled"
                          sx={{
                            marginBottom: "1rem",
                          }}
                        >
                          <InputLabel
                            id="original-language-label"
                            sx={{
                              marginBottom: "2rem",
                              color: "white !important",
                            }}
                          >
                            עברית
                          </InputLabel>
                          <Select
                            disabled
                            sx={{
                              marginBottom: "2rem",
                              color: "white !important",
                            }}
                            labelId="original-language-label"
                            defaultValue="עברית"
                            label="עברית"
                            value={originalLanguage}
                          >
                            <MenuItem value="עברית">עברית</MenuItem>
                          </Select>
                        </FormControl>
                      </FormControl>
                      <FormControl
                        fullWidth
                        variant="filled"
                        sx={{
                          marginBottom: "1rem",
                        }}
                      >
                        <Typography variant="h6">שפה מתורגמת</Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            marginBottom: "1rem",
                          }}
                        >
                          בחר את השפה שבה תרצה לתרגם את הסרטון
                        </Typography>
                        <FormControl
                          fullWidth
                          variant="filled"
                          sx={{
                            marginBottom: "1rem",
                          }}
                        >
                          <Select
                            labelId="original-language-label"
                            defaultValue="בחר"
                            label="עברית"
                            value={translatedLanguage}
                            onChange={handleTranslatedLanguageChange}
                            sx={{
                              marginBottom: "2rem",
                              color: "white",
                            }}
                          >
                            {selectedLanguagePlane.map((language) => (
                              <MenuItem
                                key={language.code}
                                value={language.label}
                              >
                                {language.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </FormControl>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <UploadButton
                          options={options}
                          onUpdate={({ uploadedFiles }) =>
                            handleFileUploadUpdate(uploadedFiles)
                          }
                          onComplete={(files) =>
                            handleFileUploadComplete(files)
                          }
                        >
                          {({ onClick }) => (
                            <button
                              className="button-69"
                              onClick={onClick}
                              disabled={disabled}
                            >
                              העלאת סרטון
                            </button>
                          )}
                        </UploadButton>
                      </div>
                    </form>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <div>
              <Box
                sx={{
                  width: "100%",
                  padding: "35px 20px",
                  borderBottom: "1px solid grey",
                  borderBottomWidth: 1,
                }}
              >
                <Container maxWidth="lg">
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="h4">הסרטונים שלי</Typography>
                      <Typography variant="h5">
                        כאן תוכל למצוא את כל הסרטונים שלך
                      </Typography>
                    </Grid>
                    {loading ? ( // Show CircularProgress while loading
                      <CircularProgress size={24} />
                    ) : showUploadButton ? (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <label htmlFor="upload-input" className="button-64">
                          <span
                            className="text"
                            onClick={() => setVideoPreview(true)}
                          >
                            העלאת סרטון
                          </span>
                        </label>
                      </Grid>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          onClick={handlePurchaseVideo}
                          sx={{
                            width: isMobile ? "80%" : "400px",
                            fontSize: "20px",
                            marginTop: theme.spacing(2),
                          }}
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "לרכישת סרטון"
                          )}
                        </Button>
                        <Dialog open={openDialog} onClose={handleCloseDialog}>
                          <DialogTitle>טופס רכישת סרטון</DialogTitle>
                          <DialogContent>
                            <IframePage dialogUrl={url} />
                          </DialogContent>
                        </Dialog>
                      </>
                    )}
                  </Grid>
                </Container>
              </Box>
              <Container maxWidth="xl">
                {videoList.map((video, index) => (
                  <MediaFileItem
                    key={index}
                    filename={video.videoName}
                    duration={video.duration}
                    dimensions={video.dimensions}
                    size={video.size}
                    frameRate={video.frameRate}
                    thumbnail={video.thumbnail}
                    url={video}
                  />
                ))}
              </Container>
            </div>
          )}
        </div>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default NewUserPortal;
