import React, { useState } from "react";
import { Route, Routes } from "react-router-dom"; // Import Routes
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import SuccessPage from "../pages/paymentCalls/Success";
import FailurePage from "../pages/paymentCalls/Failure";
import IframePage from "../pages/paymentCalls/IframePage";
import HiddenPage from "../pages/paymentCalls/HiddenPage";
import ChoosePlan from "../pages/choosePlan/ChoosePlan";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
const PayRouter = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [dialogUrl, setDialogUrl] = React.useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState(""); // Add a state for the selected plan

  const [price, setPrice] = useState<number>(0);
  const [fullName, setFullName] = useState<string>("");
  const [LowProfileDealGuid, setLowProfileDealGuid] = useState<string>("");

  const handleProgramSelect = async (program: string) => {
    try {
      const baseURL = process.env.REACT_APP_API_URL;

      const response: AxiosResponse = await axios.put(
        `${baseURL}/user/selectprogram/${userId}`,
        {
          selectedProgram: program,
        }
      );

      if (response.status === 200) {
        console.log("Program selection successful");
        localStorage.setItem("program", program);
        // Navigate to the appropriate route, e.g., "/UserPortal"
        navigate("/UserPortal");
      } else {
        console.error("Program selection failed");
        // Handle the failure gracefully, show an error message, etc.
      }
    } catch (error: any) {
      console.error("Program selection failed:", error);
      // Handle the failure gracefully, show an error message, etc.
    }
  };
  return (
    <Routes>
      {" "}
      <Route
        path="/"
        element={
          <ChoosePlan
            userId={userId}
            setUserId={setUserId}
            open={open}
            setOpen={setOpen}
            dialogUrl={dialogUrl}
            setDialogUrl={setDialogUrl}
            setSelectedPlan={setSelectedPlan}
            setPrice={setPrice}
            price={price}
            fullName={fullName}
            setFullName={setFullName}
            selectedPlan={selectedPlan}
            setLowProfileDealGuid={setLowProfileDealGuid}
            LowProfileDealGuid={LowProfileDealGuid}
          />
        }
      />
      <Route
        path="/IframePage"
        element={<IframePage dialogUrl={dialogUrl} />}
      />
      {/* Wrap Route components inside Routes */}
      <Route
        path="/Success"
        element={
          <SuccessPage
            userId={userId}
            handleProgramSelect={handleProgramSelect}
            selectedPlan={selectedPlan}
            price={price}
            fullName={fullName}
            LowProfileDealGuid={LowProfileDealGuid}
          />
        }
      />
      <Route path="/Failure" element={<FailurePage />} />
      <Route path="/HiddenPage" element={<HiddenPage />} />
    </Routes>
  );
};

export default PayRouter;
