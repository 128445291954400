import china from "../assets/image/flags/china.png";
import france from "../assets/image/flags/france.png";
import jordan from "../assets/image/flags/jordan.png";
import germany from "../assets/image/flags/germany.png";
import italy from "../assets/image/flags/italy.png";
import japan from "../assets/image/flags/japan.png";
import korea from "../assets/image/flags/south-korea.png";
import portugal from "../assets/image/flags/portugal.png";
import russia from "../assets/image/flags/russia.png";
import spain from "../assets/image/flags/spain.png";
import unitedKingdom from "../assets/image/flags/united-kingdom.png";
import unitedStates from "../assets/image/flags/united-states.png";
import arabic from "../assets/image/flags/arabic.png";
import india from "../assets/image/flags/india.png";
import ukraine from "../assets/image/flags/ukraine.png";
import southAfrica from "../assets/image/flags/south-africa.png";
import unitedArabEmirates from "../assets/image/flags/united-arab-emirates.png";
import egypt from "../assets/image/flags/egypt.png";
import iraq from "../assets/image/flags/iraq.png";
export interface LanguagesData {
  code: string;
  label: string;
  plan: "silver" | "gold" | "basic";
  img?: string;
}
export const languages: LanguagesData[] = [
  {
    code: "en-us",
    label: "אנגלית (ארצות הברית)",
    plan: "basic",
    img: unitedStates,
  },
  { code: "es-es", label: "ספרדית (ספרד)", plan: "silver", img: spain },
  { code: "fr-fr", label: "צרפתית (צרפת)", plan: "silver", img: france },
  { code: "ar-jo", label: "ערבית (ירדן)", plan: "silver", img: jordan },
  { code: "de-de", label: "גרמנית (גרמניה)", plan: "gold", img: germany },
  { code: "it-it", label: "איטלקית (איטליה)", plan: "gold", img: italy },
  { code: "pt-pt", label: "פורטוגזית (פורטוגל)", plan: "gold", img: portugal },
  { code: "ru-ru", label: "רוסית (רוסיה)", plan: "gold", img: russia },
  { code: "zh-cn", label: "סינית (סין)", plan: "gold", img: china },
  { code: "ja-jp", label: "יפנית (יפן)", plan: "gold", img: japan },
  { code: "ko-kr", label: "קוריאנית (דרום קוריאה)", plan: "gold", img: korea },
  {
    code: "ar-ae",
    label: "ערבית (איחוד האמירויות)",
    plan: "gold",
    img: unitedArabEmirates,
  },
  { code: "hi-in", label: "הינדי (הודו)", plan: "gold", img: india },
  { code: "ar-eg", label: "ערבית (מצרים)", plan: "gold", img: egypt },
  { code: "ar-iq", label: "ערבית (עיראק)", plan: "gold", img: iraq },
  { code: "it-it", label: "איטלקית", plan: "gold", img: italy },
  { code: "ja-jp", label: "יפנית", plan: "gold", img: japan },
  { code: "ru-ru", label: "רוסית", plan: "gold", img: russia },
  { code: "uk-ua", label: "אוקראינית", plan: "gold", img: ukraine },
  {
    code: "zu-za",
    label: "זולו (דרום אפריקה)",
    plan: "gold",
    img: southAfrica,
  },
  { code: "hi-in", label: "הינדי (הודו)", plan: "gold", img: india },

];
