// HiddenPage.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";

const HiddenPage: React.FC = () => {
  const [response, setResponse] = useState<string>("");



  return (
    <div>
      <h2>Hidden Page</h2>
      <div>
        <h3>Response from Server:</h3>
        <p>{response}</p>
      </div>
      {/* Add any additional content as needed */}
    </div>
  );
};

export default HiddenPage;
