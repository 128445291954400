import React from "react";
import { Container, Typography, TypographyProps, Box } from "@mui/material";
import styled from "styled-components";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const StyledPageWrapper = styled.div`
  background: rgb(16, 9, 25);
  background: radial-gradient(
    circle,
    rgba(16, 9, 25, 1) 0%,
    rgba(21, 11, 44, 1) 100%
  );
  color: white; // Example text color for better visibility on the dark background
`;
const SubTitle = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;
const VisionBox = styled(Box)`
  margin-top: 24px;
  background-color: #f0f0f7;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const VisionTitle = styled(Typography)<TypographyProps>`
  && {
    font-weight: bold;
    color: #333;
    margin-bottom: 16px; // Adds spacing between title and paragraph
  }
`;

const VisionText = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.4rem !important;
    color: #666 !important;
    line-height: 1.6 !important;
    margin-bottom: 50px !important;
  }
`;

const StyledSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled(Typography)`
  && {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;
const OurProduct: React.FC = () => {
  return (
    <StyledPageWrapper>
      <Header />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom component="div">
          המוצר שלנו
        </Typography>

        <VisionBox>
          <VisionTitle variant="h4" component="div">
            המוצר שלנו
          </VisionTitle>
          <VisionText paragraph>
            ברוך הבא ל-THUNDER, הפתרון המתקדם ביותר לדיבוב ותרגום וידאו בעידן
            הדיגיטלי.
          </VisionText>

          <VisionText paragraph>
            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                חדשנות בדיבוב ותרגום
              </SectionTitle>
              <ul>
                <li
                  style={{
                    listStyle: "none",
                  }}
                >
                  THUNDER משלב טכנולוגיית AI מתקדמת עם אלגוריתמים חדשניים ליצירת
                  דיבובים ותרגומים אוטומטיים, מדויקים וטבעיים. המערכת שלנו
                  מסוגלת להתמודד עם מגוון רחב של שפות ולהתאים עצמה לסגנון ולרגש
                  הייחודיים של כל פרויקט.{" "}
                </li>
              </ul>
            </StyledSection>

            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                איכות ללא פשרות
              </SectionTitle>
              <ul>
                <li
                  style={{
                    listStyle: "none",
                  }}
                >
                  התחייבותנו לאיכות בלתי מתפשרת מתבטאת בכל אספקט של THUNDER.
                  מהבחירה בקולות דיבוב מתאימים ועד לדיוק הטקסטים המתורגמים, כל
                  פרט נבדק ומושקע בו ליטוש כדי להבטיח שהתוצאה הסופית תהיה
                  מושלמת.
                </li>
              </ul>
            </StyledSection>

            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                ממשק פשוט וידידותי
              </SectionTitle>
              <ul>
                <li>
                  אנו מאמינים שטכנולוגיה מתקדמת לא צריכה להיות מסובכת. לכן,
                  THUNDER מציע ממשק משתמש נוח ואינטואיטיבי, המאפשר לכל אחד
                  להשתמש בו בקלות, ללא צורך בידע טכני מוקדם.
                </li>
              </ul>
            </StyledSection>

            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                מותאם אישית לצרכיך
              </SectionTitle>
              <ul>
                <li>
                  ניתן להתאים את THUNDER לצרכים ספציפיים שלך, בין אם אתה יוצר
                  תוכן, חברת מדיה או ארגון עסקי. הפלטפורמה שלנו מספקת גמישות
                  לעבוד על פרויקטים בכל קנה מידה, מסרטונים קצרים ועד יצירות
                  ארוכות.
                </li>
              </ul>
            </StyledSection>

            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                ללא הגבלות
              </SectionTitle>
              <ul>
                <li>
                  עם THUNDER, השמים הם הגבול. ניתן להשתמש במוצר שלנו ליצירת תוכן
                  בכל שפה, לכל קהל ובכל מדיום. אנו עומדים לרשותך לעזור להגיע
                  לקהלים חדשים ולפרוץ מחסומים תרבותיים.
                </li>
              </ul>
            </StyledSection>
          </VisionText>
        </VisionBox>

        {/* Use StyledSection for other sections as well */}
      </Container>
      <Footer />
    </StyledPageWrapper>
  );
};

export default OurProduct;
