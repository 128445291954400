import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  List,
  ListItem,
  Card,
  CardContent,
  Avatar,
  SelectChangeEvent,
  DialogContent,
  Dialog, // Import TypeScript definition for event types
} from "@mui/material";
import axios from "axios";
import { purple, red, grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import NavbarPortalProfile from "./NavbarPortalProfile";
import NavbarPortal from "./NavbarPortal";
import { clearUserData } from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import IframePage from "../singlePaymentCall/IframePage";

type ProfilePageProps = {
  iduser: string; // or the appropriate type for iduser
  userName: string;
  email: string;
};
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: purple[700],
  },
  "&.Mui-disabled": {
    backgroundColor: grey[400],
    color: grey[50],
  },
}));

const ColorDeleteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: purple[500],
  "&:hover": {
    backgroundColor: purple[700],
    color: red[500],
  },
}));

const Profile: React.FC<ProfilePageProps> = ({ iduser, userName, email }) => {
  const [subscription, setSubscription] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [price, setPrice] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [dialogUrl, setDialogUrl] = useState<string>("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubscriptionChange = (event: SelectChangeEvent<string>) => {
    const newSubscription = event.target.value;
    debugger;
    setSubscription(newSubscription);

    // Implement your logic to determine the price based on the selected plan
    const newPrice = determinePriceForPlan(newSubscription); // Placeholder function
    handleClickOpen(newSubscription, newPrice);
  };

  const determinePriceForPlan = (plan: string): number => {
    // Placeholder logic, replace with your actual pricing model
    switch (plan) {
      case "basic":
        return 0;
      case "silver":
        return 159;
      case "pro":
        return 229;
      default:
        return 0; // Default price if plan not recognized
    }
  };

  const handleClickOpen = (program: string, price: number) => {
    setSelectedPlan(program);
    setPrice(price);
    sendCardcomPaymentRequest(program, price);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogUrl("");
  };

  const sendCardcomPaymentRequest = async (selectedPlan: any, price: any) => {
    debugger;
    try {
      const baseURL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${baseURL}/pay/payment-request`, {
        selectedPlan,
        price,
        userEmail: email,
        fullName: userName,
      });
      const { data } = response;

      if (data) {
        const responseString = data;
        const responseArray = responseString.split("&");
        let url = "";

        for (const pair of responseArray) {
          const [key, value] = pair.split("=");

          if (key === "url") {
            url = decodeURIComponent(value);
            break; // Exit the loop once 'url' is found
          }
        }

        const parsedUrl = new URL(url);
        const lowProfileCode = parsedUrl.searchParams.get("LowProfileCode");

        if (lowProfileCode !== null) {
          localStorage.setItem("lowProfileCode", lowProfileCode);
          // Assuming you have a method or state to track the lowProfileCode
          // setLowProfileCode(lowProfileCode); // Implement this if necessary
        }
        setDialogUrl(url); // Set the URL to open in the dialog
        setOpen(true); // Open the dialog
      }
    } catch (error) {
      console.error(
        "Error:",
        axios.isAxiosError(error) ? error.message : "An unknown error occurred"
      );
    }
  };
  const handleLogout = () => {
    dispatch(clearUserData());
    Cookies.remove("userToken"); // Adjust according to your application's cookies
    navigate("/signin");
  };
  return (
    <>
      <NavbarPortal userName={userName} />
      <Grid
        container
        sx={{
          backgroundcolor: "#1c1d21",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <Grid item xs={12} md={3}>
          <NavbarPortalProfile />
        </Grid>
        <Grid item xs={12} md={9}>
          <div
            style={{
              backgroundColor: "#1c1d21",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#1c1d21",
                borderBottom: "1px solid #ffffff20",
                borderRight: "1px solid #ffffff20",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "24px",
              }}
            >
              <Typography variant="h4" color="white">
                פרופיל
              </Typography>
              <Typography variant="h6" color="white">
                ניהול הגדרות חשבון שלך
              </Typography>
            </Box>
            <Box
              sx={{
                minHeight: "100vh",
                p: 3,
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <Card
                sx={{ width: "min(100%,469px)", backgroundColor: "#1c1d21" }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",

                      flexDirection: "column",
                      mb: 2,
                    }}
                  >
                    <Avatar
                      alt={userName}
                      sx={{ height: "72px", width: "72px" }}
                    />

                    <Typography
                      variant="h5"
                      sx={{
                        color: "white",
                      }}
                    >
                      {userName}
                    </Typography>
                  </Box>
                  <Typography
                    variant="overline"
                    display="block"
                    sx={{ mb: 2, color: "white", fontSize: "1.2rem" }}
                  >
                    שיטת התחברות
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    label="Email Address"
                    value={email}
                    fullWidth
                    margin="normal"
                    sx={{
                      alignItems: "center",
                      backgroundColor: "#292a30", // This needs to be a valid value from your theme or a CSS variable defined elsewhere
                      borderRadius: "6px",
                      display: "flex",
                      color: "white",
                      justifyContent: "space-between",

                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white",
                        },
                        "&:hover fieldset": {
                          borderColor: "white",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white",
                        },
                        "& .MuiInputBase-input": {
                          color: "white",
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "white",
                        },
                        "& .MuiOutlinedInput-input": {
                          color: "white",
                        },
                        ":-khtml-any-link. .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "white",
                          },
                      },
                    }}
                    // If you're using a theme, you might want to use the theme's palette
                    // backgroundColor: theme => theme.palette.grey[800],
                  />
                  <Typography
                    variant="overline"
                    display="block"
                    sx={{ mb: 2, color: "white", fontSize: "1.2rem" }}
                  >
                    ניהול מנוי
                  </Typography>
                  <Select
                    value={subscription}
                    onChange={handleSubscriptionChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      mb: 2,
                      color: "white",
                      backgroundColor: "#292a30",
                      borderRadius: "6px",
                      width: "100%",
                    }}
                  >
                    <MenuItem value="" disabled>
                      בחר מנוי
                    </MenuItem>
                    <MenuItem value={"basic"}>Basic</MenuItem>
                    <MenuItem value={"silver"}>Premium</MenuItem>
                    <MenuItem value={"pro"}>Pro</MenuItem>
                  </Select>

                  <List
                    sx={{
                      borderTop: "1px solid #ffffff20",
                    }}
                  >
                    <ListItem button onClick={handleLogout}>
                      <ColorButton
                        variant="contained"
                        fullWidth
                        sx={{
                          mb: 1,
                          backgroundColor: "#9c27b0",
                          color: "white",
                        }}
                      >
                        התנתקות
                      </ColorButton>
                    </ListItem>
                    <ListItem button>
                      <ColorDeleteButton
                        variant="contained"
                        fullWidth
                        sx={{ mb: 1 }} // New background color
                      >
                        מחיקת חשבון
                      </ColorDeleteButton>{" "}
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Box>
          </div>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {dialogUrl && <IframePage dialogUrl={dialogUrl} />}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Profile;
