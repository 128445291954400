import styled, { css } from "styled-components";
import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const StyledCard = styled(MuiCard)`
  && {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1000px;
    margin: 20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
      box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    }
  }
`;
export const CardHeader = styled.div<{ isFeatured: boolean }>`
  background-color: ${(props) => (props.isFeatured ? "#1976d2" : "#eee")};
  color: ${(props) => (props.isFeatured ? "white" : "black")};
  padding: 16px;
  font-size: 20px;
  text-align: center;
`;

export const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
`;
export const PlanTitleTypography = styled(Typography)`
  && {
    position: relative; /* Needed for positioning the ::after pseudo-element */
    font-size: 2.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;

    color: white;
    margin-bottom: 1rem;

    &::after {
      content: "";
      position: absolute;
      bottom: -5px; /* Adjust based on your design needs */
      left: 0;
      right: 0;
      margin: auto;
      height: 2px; /* Thickness of the underline */
      background-color: grey; /* Or any color you prefer */
    }
  }
`;
export const PriceTypography = styled(Typography)`
  position: relative;

  font-size: 2rem !important;
  color: white;

  font-weight: 200;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 1rem;
  padding: 0.9rem 0;
  margin-bottom: 1.5rem;

  &::after {
    content: "";
    position: absolute;
    bottom: -5px; /* Adjust based on your design needs */
    left: 0;
    right: 0;
    height: 2px; /* Thickness of the underline */
    background-color: grey; /* Matching the text color or any color you prefer */
  }
`;
export const FeatureItem = styled.li`
  margin: 10px 0;
`;

export const Button = styled(MuiButton)`
  && {
    background-color: initial;
    background-image: linear-gradient(#8614f8 0, #760be0 100%);
    border-radius: 5px;
    border-style: none;
    box-shadow: rgba(245, 244, 247, 0.25) 0 1px 1px inset;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 25px;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    margin-left: -4px;
    outline: 0;
    text-align: center;
    transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: bottom;
    width: 190px;
    margin-bottom: 4rem;
    display: flex;
    &:hover {
      opacity: 0.7;
    }
  }
`;
export const StyledList = styled.ul`
  padding: 0;
  margin: 20px 0;
  list-style-type: none; // Removes default list styling
  font-size: 1.4rem; // Adjusts the font size
  color: white; // Adjusts the font color
  line-height: 2.8; // Adjusts the line height
`;

// Styled component for the list items
export const StyledListItem = styled.li`
  display: flex;
  align-items: center; // Aligns the icon and text vertically
  margin: 10px 0;
`;

// Styled component for the CheckIcon, if you want to customize it
export const StyledCheckIcon = styled(CheckCircleOutlineIcon)`
  color: green; // Example color, adjust as needed
  margin-right: 8px; // Adds some space between the icon and the text
`;
