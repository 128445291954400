import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  Container,
  Modal,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube"; // Assuming YouTubeIcon is used as a placeholder for GoogleIcon
import SendIcon from "@mui/icons-material/Send";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#100919",
        color: "white",
        py: { xs: 3, sm: 5 },
        paddingTop: { xs: "3rem", sm: "5rem" },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ justifyContent: "space-between" }}>
          {/* Contact Information */}
          <Grid item xs={12} sm={6} md={4}>
            <Box display="flex" alignItems="center" mb={2}>
              <LocationOnIcon sx={{ mr: 1 }} />
              <Typography variant="body1">אבן גבירול 120, תל אביב</Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={2}>
              <PhoneIcon sx={{ mr: 1 }} />
              <Typography variant="body1">054-7938-785</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ mr: 1 }} />
              <Link
                href="mailto:contact@thunder.co.il"
                sx={{ color: "white", textDecoration: "none" }}
              >
                <Typography variant="body1">contact@thunder.co.il</Typography>
              </Link>
            </Box>
          </Grid>

          {/* Logo and About */}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              textAlign: { xs: "center", md: "center" },
              my: { xs: 4, md: 0 },
            }}
          >
            <Typography variant="h3" gutterBottom>
              THUNDER
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              כל הזכויות שמורות <br /> 2024 © חברת THUNDER
            </Typography>
            {/* Social Icons */}
            <Box>
              <Link href="#" sx={{ mx: 1 }}>
                <FacebookIcon />
              </Link>
              <Link href="#" sx={{ mx: 1 }}>
                <TwitterIcon />
              </Link>
              <Link href="#" sx={{ mx: 1 }}>
                <YouTubeIcon />{" "}
                {/* Assuming YouTubeIcon is used as a placeholder for GoogleIcon */}
              </Link>
            </Box>
          </Grid>

          {/* Subscription Form */}
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Typography variant="h6" gutterBottom>
              רוצה לקבל עדכונים?
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              אל תפספס להירשם לעדכונים שלנו, אנא מלא את הטופס למטה.
            </Typography>
            <Box
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <TextField
                variant="outlined"
                placeholder="דואר אלקטרוני"
                size="small"
                sx={{
                  bgcolor: "white",
                  borderRadius: 1,
                  mr: { sm: 1 },
                  mb: { xs: 2, sm: 0 },
                }}
              />
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                sx={{
                  py: 1,
                  backgroundColor: "#8017ef",
                  borderRadius: 1,
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#7016de",
                  },
                }}
              >
                שלח
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Link href="#" onClick={handleOpen} sx={{ mx: 1, cursor: "pointer" }}>
          מדיניות פרטיות
        </Link>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
            maxHeight: "90%",
          }}
        >
          <PrivacyPolicy />
        </Box>
      </Modal>
    </Box>
  );
};

export default Footer;
