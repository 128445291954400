import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  Grid,
} from "@mui/material";
import {
  SigninSection,
  SinginLeft,
  SinginRight,
  HeaderContainer,
  SigninContainerButton,
  Button55,
} from "./Singin.style";
import { Alert } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import sing from "../../assets/image/sing.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "../../redux/authSlice";
import { RootState } from "../../redux/store";
import ForgotPassword from "./ForgotPassword";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider } from "@mui/system";
import { createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";

interface SignInFormInputs {
  email: string;
  password?: string;
  selectedProgram?: string;
  // remember: boolean;
}

const SignIn: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormInputs>();
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize the dispatch function

  const user = useSelector((state: RootState) => state.auth.user); // Replace "auth" with your Redux slice name
  console.log("user", user);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [openModal, setOpenModal] = useState(false);

  const [verificationStatus, setVerificationStatus] =
    React.useState<string>("");
  // Check if the user is authenticated (adjust this logic based on your auth state structure)
  console.log("user", user);
  React.useEffect(() => {
    if (user && !user?.isVerified) {
      setVerificationStatus("אימות האימייל נכשל.");
    } else {
      setVerificationStatus("");
    }
  }, [user]);

  const onSubmit = async (data: SignInFormInputs) => {
    try {
      const baseURL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${baseURL}/user/login`, data);
      debugger;
      if (response.status === 200) {
        if (response.data.pro) {
          navigate("/UserPortal");
        } else {
          navigate("/pay");
        }

        dispatch(
          setUserData({
            email: data.email,
            selectedProgram: response.data.selectedProgram,
          })
        );
      }
    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response) {
        if (
          error.response.data &&
          error.response.data.msg === "Email not verified"
        ) {
          setErrorMessage("המייל לא אומת");
        } else if (error.response.status === 401) {
          setErrorMessage("סיסמא שגויה. אנא בדוק את הסיסמא שהוזנה ונסה שוב.");
        } else {
          setErrorMessage("שגיאה בתהליך ההתחברות. נסה שנית.");
        }
      } else {
        setErrorMessage("שגיאה כללית בהתחברות");
      }
    }
  };
  const openModalHandler = () => {
    setOpenModal(true);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };
  const theme = createTheme({
    direction: "rtl",
  });

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <SigninSection container>
          <SinginLeft item xs={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h5" gutterBottom>
                <HeaderContainer>ברוכים הבאים</HeaderContainer>
              </Typography>
              <TextField
                label="דואר אלקטרוני"
                type="email"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "white", // This will change the border color to black on hover
                    },
                  },

                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white",
                    },

                  "& .MuiOutlinedInput-input": {
                    color: "white",
                  },
                  "& .MuiFormLabel-root": {
                    color: "white",
                  },

                  "& .MuiFormHelperText-root": {
                    color: "white",
                  },
                }}
                margin="normal"
                variant="outlined"
                {...register("email", { required: "דרושה איימל" })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
              <TextField
                label="סיסמא"
                type="password"
                fullWidth
                sx={{
                  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "& .MuiInputLabel-root": {
                    color: "white",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "white",
                    },
                  "& .MuiOutlinedInput-input": {
                    color: "white",
                  },
                  "& .MuiFormLabel-root": {
                    color: "white",
                  },
                  "& .MuiFormHelperText-root": {
                    color: "white",
                  },
                }}
                margin="normal"
                variant="outlined"
                {...register("password", { required: "דרושה סיסמא" })}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
              <SigninContainerButton container>
                {/* <Grid item xs={12} md={9}>
              <FormControlLabel
                control={<Checkbox {...register("remember")} />}
                label="השאר אותי מחובר"
              />
            </Grid> */}

                <Grid item xs={12} md={12}>
                  <Link
                    onClick={openModalHandler}
                    underline="hover"
                    sx={{
                      color: "white",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#760BE0",
                      },
                    }}
                  >
                    שחכתי סיסמא
                  </Link>
                </Grid>
              </SigninContainerButton>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

              <Button
                type="submit"
                variant="contained"
                fullWidth
                style={{
                  marginTop: "1rem",
                  backgroundColor: "#760BE0",
                  color: "white",
                }}
              >
                התחברות
              </Button>
              <Typography
                style={{
                  marginTop: "1rem",
                  textAlign: "center",
                  color: "white",
                }}
              >
                אין לך עדיין משתמש?{" "}
                <Link
                  href="/SignUp"
                  underline="hover"
                  sx={{
                    color: "white",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#760BE0",
                    },
                  }}
                >
                  לחץ כאן
                </Link>
              </Typography>
            </form>
          </SinginLeft>
          <Dialog open={openModal} onClose={closeModalHandler}>
            <DialogContent>
              <ForgotPassword />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeModalHandler} color="primary">
                סגור
              </Button>
            </DialogActions>
          </Dialog>
        </SigninSection>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default SignIn;
