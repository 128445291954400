import React from "react";
import { Container, Typography, TypographyProps, Box } from "@mui/material";
import styled from "styled-components";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const StyledPageWrapper = styled.div`
  background: rgb(16, 9, 25);
  background: radial-gradient(
    circle,
    rgba(16, 9, 25, 1) 0%,
    rgba(21, 11, 44, 1) 100%
  );
  color: white; // Example text color for better visibility on the dark background
`;
const SubTitle = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;
const VisionBox = styled(Box)`
  margin-top: 24px;
  background-color: #f0f0f7;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const VisionTitle = styled(Typography)<TypographyProps>`
  && {
    font-weight: bold;
    color: #333;
    margin-bottom: 16px; // Adds spacing between title and paragraph
  }
`;

const VisionText = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.4rem !important;
    color: #666 !important;
    line-height: 1.6 !important;
    margin-bottom: 50px !important;
  }
`;

const StyledSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled(Typography)`
  && {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;
const ToolsPage: React.FC = () => {
  return (
    <StyledPageWrapper>
      <Header />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom component="div">
          הכלים שלנו{" "}
        </Typography>

        <VisionBox>
          <VisionText paragraph>
            ב-THUNDER, אנו מאמינים בכוח של טכנולוגיה חדשנית לשנות את חווית
            הדיבוב והתרגום. הנה מבט על כלים שפיתחנו להבטחת חוויית משתמש חלקה
            ויעילה.
          </VisionText>

          <StyledSection>
            <SectionTitle variant="h4" gutterBottom>
              ממיר טקסט-לדיבור מתקדם (בבניה)
            </SectionTitle>
            <VisionText paragraph>
              ממיר הטקסט-לדיבור המתקדם שלנו בפיתוח יאפשר המרה אוטומטית של טקסט
              לדיבור באיכות גבוהה. הממיר מיועד לשימוש במגוון שפות ולהתאמה לטונים
              ומקצבים שונים.
            </VisionText>
          </StyledSection>

          <StyledSection>
            <SectionTitle variant="h4" gutterBottom>
              מערכת סינכרון שפתיים (בבניה)
            </SectionTitle>
            <VisionText paragraph>
              המערכת החדשה שלנו לסינכרון שפתיים תאפשר יצירת תוצאות דיבוב טבעיות
              ומדויקות יותר. המערכת משתמשת באלגוריתמים מתקדמים להתאמה בין הקול
              לתנועות השפתיים בווידאו.
            </VisionText>
          </StyledSection>

          <StyledSection>
            <SectionTitle variant="h4" gutterBottom>
              פלטפורמת ניהול תרגומים (בפיתוח)
            </SectionTitle>
            <VisionText paragraph>
              פלטפורמת ניהול התרגומים שלנו תאפשר למשתמשים לנהל ביעילות פרויקטים
              מרובי שפות, לעקוב אחר התקדמות העבודה ולשמור על עקביות התוכן.
            </VisionText>
          </StyledSection>

          <StyledSection>
            <SectionTitle variant="h4" gutterBottom>
              מנוע ניתוח טקסט (בשלבי פיתוח)
            </SectionTitle>
            <VisionText paragraph>
              המנוע שלנו לניתוח טקסט יספק פרספקטיבה מעמיקה על הטקסט המדובב, כולל
              זיהוי נושאים מרכזיים, טונים ומנהגים תרבותיים. הדבר יסייע ביצירת
              תרגומים יותר מדויקים ומותאמים תרבותית.
            </VisionText>
          </StyledSection>

          <StyledSection>
            <SectionTitle variant="h4" gutterBottom>
              כלי עריכת וידאו (בתכנון)
            </SectionTitle>
            <VisionText paragraph>
              כלי העריכה שלנו לווידאו יאפשר למשתמשים לשלב בקלות דיבוב ותרגום
              בתוך הווידאו המקורי, תוך שמירה על איכות המקור.
            </VisionText>
          </StyledSection>

          <SectionTitle paragraph>
            בא לך לשמוע עוד? הכלים שלנו נמצאים בשלבים שונים של פיתוח ובניה, ואנו
            מחויבים להמשיך ולחדש בתחום הדיבוב והתרגום. אנו מזמינים אתכם להישאר
            מעודכנים בהתפתחויות החדשות ולהצטרף אלינו במסע לשינוי העולם של דיבוב
            ותרגום.
          </SectionTitle>
        </VisionBox>
      </Container>
      <Footer />
    </StyledPageWrapper>
  );
};

export default ToolsPage;
