import React from "react";
import { Container } from "@mui/material";
import styled from "styled-components";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import danielImage from "./da.jpeg";
import oshriImage from "./osh.jpg";
// Styled components for the page

const Introduction = styled.p`
  margin: 1rem 0;
  font-size: 1.4rem; // Slightly larger for better readability
  text-align: center;
  color: #e0e0e0; // Lighter shade for a bit more contrast against the dark background
  line-height: 1.6; // Improved line spacing
`;

const TeamMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem; // Increase bottom margin for better spacing
  padding: 1rem; // Add padding around each profile for separation
  background-color: rgba(255, 255, 255, 0.1); // Slight background for emphasis
  border-radius: 10px; // Rounded corners for a softer look
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); // Subtle shadow for depth

  @media (max-width: 768px) {
    justify-content: space-around;
  }
`;

const TeamMemberImage = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
`;

const TeamMemberName = styled.h2`
  font-size: 1.2rem; // Ensure the role is clearly readable
  color: #a9a9a9; // Softer color for the role description
  margin: 0.5rem 0; // Adjust spacing around the role description
  font-style: italic; // Italicize to differentiate from the name
  line-height: 1.4; // Tighten the line spacing a bit
`;

const TeamMemberRole = styled.p`
  font-size: 1.2rem;
  color: #e0e0e0; // Lighter shade for better contrast
`;
const StyledPageWrapper = styled.div`
  background: rgb(16, 9, 25);
  background: radial-gradient(
    circle,
    rgba(16, 9, 25, 1) 0%,
    rgba(21, 11, 44, 1) 100%
  );
  color: white; // Example text color for better visibility on the dark background
`;
// Example team members data
const teamMembers = [
  { name: "אדם ראשון", role: 'מנכ"ל' },
  { name: "חווה ראשונה", role: "מנהלת שיווק" },
  // Add more team members as needed
];

const OurTeamPage: React.FC = () => {
  return (
    <StyledPageWrapper>
      <Header />
      <Container>
        <Introduction>
          ב-THUNDER, אנו מאמינים שהעוצמה נמצאת באנשים שמאחורי הטכנולוגיה.
          צוותנו, אף על פי שהוא קטן, מתאפיין במומחיות וחדשנות. כל אחד מחברי
          הצוות שלנו מביא עימו תשוקה ליצירת חוויות דיבוב ותרגום שאין להן תחליף.
          פגשו את האנשים שמניעים את THUNDER קדימה:
        </Introduction>

        <TeamMemberContainer>
          <TeamMemberImage
            src={danielImage}
            alt="Daniel Tokbakin - Founder & CEO"
          />
          <TeamMemberName>דניאל טוקבקין - Founder & CEO</TeamMemberName>
          <TeamMemberRole>
            דניאל, מייסד ומנכ"ל החברה, הוא המנוע מאחורי החזון של THUNDER. עם רקע
            עשיר בעולם הטכנולוגיה והיזמות, דניאל מוביל את החברה עם תשוקה לחדשנות
            וצמיחה. הוא מאמין ביצירת פתרונות שמשנים את הקונספט של דיבוב ותרגום
            בעידן הדיגיטלי.
          </TeamMemberRole>
        </TeamMemberContainer>

        <TeamMemberContainer>
          <TeamMemberImage
            alt="Oshri Shakuri - Founder & Head of R&D"
            src={oshriImage}
          />
          <TeamMemberName>אושרי שקורי - Founder & Head of R&D</TeamMemberName>
          <TeamMemberRole>
            אושרי, מייסד וראש מחלקת המחקר ופיתוח, הוא הלב המדעי של החברה. בעל
            ידע עמוק וניסיון רחב בתחום ה-AI ולמידת מכונה, אושרי הוא האחראי על
            פיתוח הטכנולוגיות המתקדמות שלנו. הוא פועל ליצירת פתרונות שנותנים
            תשובה לאתגרים המתקדמים בתעשייה.
          </TeamMemberRole>
        </TeamMemberContainer>

        <Introduction>
          אנו ב-THUNDER גאים בצוות המוביל והמוכשר שלנו ונמשיך להתרחב ולהתפתח עם
          חברים חדשים שיצטרפו אלינו בדרך.
        </Introduction>
      </Container>
      <Footer />
    </StyledPageWrapper>
  );
};

export default OurTeamPage;
