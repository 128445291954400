// SuccessPage.tsx
import React, { useEffect } from "react";
import "./styles.css";
import axios from "axios";
interface SuccessPageProps {
  handleProgramSelect: (program: string) => void;
  selectedPlan: string;
  price: number;
  fullName: string;
  userId: string;
  LowProfileDealGuid: string;
}
const SuccessPage: React.FC<SuccessPageProps> = ({
  handleProgramSelect,
  selectedPlan,
  price,
  fullName,
  userId,
  LowProfileDealGuid,
}) => {
  useEffect(() => {
    const updateLowProfileCode = async () => {
      try {
        const response = await axios.put(`/user/${userId}/lowProfileCode`, {
          lowProfileCode: LowProfileDealGuid,
        });
        console.log("LowProfileCode updated:", response.data);
      } catch (error) {
        console.error("Error updating LowProfileCode:", error);
      }
    };

    updateLowProfileCode();
  }, [userId, LowProfileDealGuid]);

  return (
    <div className="page-container">
      <h2 className="success-message">התשלום ההצליח.. </h2>
      <h2 className="success-message">תודה רבה על הרכישה</h2>
      <h2 className="success-message">נא לצאת מהמסך</h2>
      {/* Add any additional content or styling as needed */}
    </div>
  );
};

export default SuccessPage;
