import React, { useState, useEffect } from "react";
import "./Header.css"; // Make sure to import the CSS file
import { Link, useNavigate } from "react-router-dom"; // Import the Link component and useNavigate hook
import { Button } from "@mui/material";
const Navbar: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 750);
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const handleToSignIn = () => {
    navigate("/signin"); // Use the navigate function to redirect to the /signin route
  };
  useEffect(() => {
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth < 1002);
    };

    window.addEventListener("resize", checkWindowSize);

    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  const toggleMobileMenu = () => {
    // Implement the logic to toggle the mobile menu
  };

  return (
    <nav className="navbar">
      {isMobile ? (
        <>
          <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
            <div className="main">
              <label className="menu-button-wrapper" htmlFor="">
                <input type="checkbox" className="menu-button" />
                <div className="icon-wrapper">
                  <label className="hamburger">
                    <input className="hamburger-input" type="checkbox" />
                    <span className="hamburger-line first" />
                    <span className="hamburger-line second" />
                    <span className="hamburger-line third" />
                  </label>
                </div>
                <div className="item-list">
                  <Link to="/about">עלינו</Link>
                  <Link to="/career">קריירה</Link>
                  <Link to="/OurProduct">המוצר שלנו</Link>
                  <Link to="/tools">כלים</Link>
                  <Link to="/ourteam">הצוות שלנו</Link>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Button
                      className="nav-button"
                      onClick={handleToSignIn}
                      sx={{
                        color: "white",
                      }}
                    >
                      התחל
                    </Button>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="nav-logo">
            <a
              href="/"
              style={{
                color: "white",
              }}
            >
              THUNDER
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="nav-logo">
            {" "}
            <a
              href="/"
              style={{
                color: "white",
              }}
            >
              THUNDER
            </a>
          </div>
          <ul className="nav-links">
            <li>
              <a href="/about" className="nav-link">
                עלינו
              </a>
            </li>
            <li>
              <a href="/career" className="nav-link">
                קריירה
              </a>
            </li>
            <li>
              <a href="/OurProduct" className="nav-link">
                המוצר שלנו
              </a>
            </li>
            <li>
              <a href="/tools" className="nav-link">
                כלים
              </a>
            </li>
            <li>
              <a href="/ourteam" className="nav-link">
                הצוות שלנו
              </a>
            </li>
          </ul>
          <button className="nav-button" onClick={handleToSignIn}>
            התחל
          </button>
        </>
      )}
    </nav>
  );
};

export default Navbar;
