import styled from "styled-components";
import vector from "./vecor.jpg";

import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Link,
  Paper,
} from "@mui/material";

export const SigninSection = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-image: url(${vector});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  justify-content: center;

  min-height: 100vh;
`;

export const SinginLeft = styled(Grid)`
  width: 100%;
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SinginRight = styled(Grid)`
  position: relative;
  z-index: 0;

  padding: 10em 3em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h1 {
    font-family: "Rubik", sans-serif;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 5em;
    color: #760be0;
    text-align: center;
    margin-bottom: 2rem;
  }
`;

export const HeaderContainer = styled.h3`
  font-family: "Rubik", sans-serif;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 1.2em;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
`;
export const SigninContainerButton = styled(Grid)`
  &.MuiGrid-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 100%;
  }
`;
export const Button55 = styled.button`
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }

  &:focus {
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
  }
`;
