import React from "react";
import { Container, Typography, TypographyProps, Box } from "@mui/material";
import styled from "styled-components";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const StyledPageWrapper = styled.div`

  background: rgb(16, 9, 25);
  background: radial-gradient(
    circle,
    rgba(16, 9, 25, 1) 0%,
    rgba(21, 11, 44, 1) 100%
  );
  color: white; // Example text color for better visibility on the dark background
`;
const SubTitle = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;
const VisionBox = styled(Box)`
  margin-top: 24px;
  background-color: #f0f0f7;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const VisionTitle = styled(Typography)<TypographyProps>`
  && {
    font-weight: bold;
    color: #333;
    margin-bottom: 16px; // Adds spacing between title and paragraph
  }
`;

const VisionText = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.4rem !important;
    color: #666 !important;
    line-height: 1.6 !important;
    margin-bottom: 50px !important;
  }
`;

const StyledSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const SectionTitle = styled(Typography)`
  && {
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 1rem;
  }
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;
const Career: React.FC = () => {
  return (
    <StyledPageWrapper>
      <Header />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom component="div">
          קריירה
        </Typography>
        <SubTitle paragraph>הצטרפו לצוות החדשני שלנו</SubTitle>

        <VisionBox>
          <VisionTitle variant="h4" component="div">
            החזון שלנו
          </VisionTitle>
          <VisionText paragraph>
            ב-THUNDER, אנחנו לא רק חברה – אנחנו מהפכה בתעשיית הדיבוב והתרגום. אם
            אתם נלהבים משבירת מחסומים לשוניים ודחיפת גבולות הטכנולוגיה
            והיצירתיות, אתם שייכים איתנו.
          </VisionText>
          <VisionTitle variant="h4" component="div">
            למה כדאי לעבוד איתנו?
          </VisionTitle>
          <VisionText paragraph>
            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                עבודה בעלת משמעות:
              </SectionTitle>
              <ul>
                <li
                  style={{
                    listStyle: "none",
                  }}
                >
                  תרמו לפרויקטים שהופכים תוכן גלובלי לנגיש לקהלים מגוונים.
                </li>
              </ul>
            </StyledSection>

            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                הזדמנויות לצמיחה:
              </SectionTitle>
              <ul>
                <li
                  style={{
                    listStyle: "none",
                  }}
                >
                  אנו מאמינים בטיפוח כשרונות ומציעים הזדמנויות פיתוח מקצועי
                  שונות.
                </li>
              </ul>
            </StyledSection>

            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                תרבות שיתופית:
              </SectionTitle>
              <ul>
                <li>הצטרפו לצוות שבו כל קול חשוב ושיתוף פעולה הוא המפתח.</li>
              </ul>
            </StyledSection>

            <StyledSection>
              <SectionTitle variant="h4" gutterBottom>
                מגוון וכלוליות:
              </SectionTitle>
              <ul>
                <li>היו חלק ממקום עבודה מגוון, כולל ותומך.</li>
              </ul>
            </StyledSection>
          </VisionText>
        </VisionBox>

        {/* Use StyledSection for other sections as well */}
      </Container>
      <Footer />
    </StyledPageWrapper>
  );
};

export default Career;
