import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom"; // Import Routes
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import SuccessPage from "../pages/singlePaymentCall/Success";
import FailurePage from "../pages/singlePaymentCall/Failure";
import IframePage from "../pages/singlePaymentCall/IframePage";
import HiddenPage from "../pages/singlePaymentCall/HiddenPage";
import ChoosePlan from "../pages/choosePlan/ChoosePlan";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
const PaySingleRouter = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState<string>("");
  const [dialogUrl, setDialogUrl] = React.useState<string>("");
  const user = useSelector((state: RootState) => state.auth.user); // Replace "auth" with your Redux slice name

  useEffect(() => {
    const getUserId = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;

      try {
        const response = await axios.get(
          `${apiUrl}/user/getuser/${user.email}`
        );
        if (response.status === 200) {
      
          const _id = response?.data?.user._id;
        

          setUserId(_id);
          localStorage.setItem("id", _id);
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    };
    getUserId();
  }, [user.email]);
  return (
    <Routes>
      {" "}
      <Route
        path="/IframePage"
        element={<IframePage dialogUrl={dialogUrl} />}
      />
      {/* Wrap Route components inside Routes */}
      <Route path="/Success" element={<SuccessPage userId={userId} />} />
      <Route path="/Failure" element={<FailurePage />} />
      <Route path="/HiddenPage" element={<HiddenPage />} />
    </Routes>
  );
};

export default PaySingleRouter;
