// SuccessPage.tsx
import React, { useEffect } from "react";
import "./styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
interface SuccessPageProps {
  userId: string;
}
const SuccessPage: React.FC<SuccessPageProps> = ({ userId }) => {
  const navigate = useNavigate();
  console.log("userId", userId);
  useEffect(() => {
    const updateCanUploadVideoStatus = async () => {
      const apiUrl = process.env.REACT_APP_API_URL;
      try {
        // Assuming the endpoint expects a boolean value to enable video uploads
        const response = await axios.put(
          `${apiUrl}/user/${userId}/can-upload-video`,
          {
            canUploadVideo: true,
          }
        );

        console.log("Update successful:", response.data);
        navigate("/userportal"); // Redirect to the user portal (or any other page as needed)
      } catch (error) {
        console.error("Error updating user status:", error);
      }
    };

    if (userId) {
      updateCanUploadVideoStatus();
    }
  }, [userId]);

  return (
    <div className="page-container">
      <h2 className="success-message">התשלום ההצליח.. </h2>
      <h2 className="success-message">תודה רבה על הרכישה</h2>

      {/* Add any additional content or styling as needed */}
    </div>
  );
};

export default SuccessPage;
