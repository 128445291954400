import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./components/header/Header";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import SignIn from "./pages/signin/Signin";
import Home from "./pages/home/Home";
import SignUp from "./pages/signup/SignUp";
import ContactUs from "./pages/home/ContactUs";
import PortalRouter from "./router/PortalRouter";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import MailVerificationPage from "./pages/mailVerification/MailVerificationPage";

import PayRouter from "./router/PayRouter";
import About from "./pages/about/About";
import PasswordReset from "./pages/signin/PasswordReset";
import PaySingleRouter from "./router/PaySingleRouter";

import Career from "./pages/career/Career";
import OurProduct from "./pages/ourproduct/OurProduct";
import ToolsPage from "./pages/toolsPage/ToolsPage";
import Ourteam from "./pages/ourteam/Ourteam";
import PrivateRoute from "./router/PrivateRoute";

function App() {
  const user = useSelector((state: RootState) => state?.auth.user); // Replace "auth" with your Redux slice name

  // Check if the user is authenticated (adjust this logic based on your auth state structure)
  const isAuthenticated = !!user.email;
  return (
    <>
      {/* <Header /> */}

      <Routes>
        <Route
          path="*"
          element={
            <>
              <h1>404 Not Found</h1>
            </>
          }
        />{" "}
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/verify/:token" element={<MailVerificationPage />} />
        <Route path="/reset-password/:token" element={<PasswordReset />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/about" element={<About />} />
        <Route path="/PaySingle/*" element={<PaySingleRouter />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/OurProduct" element={<OurProduct />} />
        <Route path="/Tools" element={<ToolsPage />} />
        <Route path="/OurTeam" element={<Ourteam />} />
        <Route element={<PrivateRoute />}>
          <Route path="/Pay/*" element={<PayRouter />} />
          <Route path="/UserPortal/*" element={<PortalRouter />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
