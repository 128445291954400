// FailurePage.tsx
import React from "react";
import "./styles.css";
const FailurePage: React.FC = () => {
  return (
    <div>
      <h2>התשלום נכשל</h2>
      <h2>נסה שוב</h2>
      {/* Add any additional content or styling as needed */}
    </div>
  );
};

export default FailurePage;
