import React from "react";
import styled from "styled-components";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import chat from "../../assets/image/icon/chat.svg";
import contactMail from "../../assets/image/icon/contact-mail.svg";
import address from "../../assets/image/icon/address.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";

import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Breadcrumbs,
} from "@mui/material";
import Header from "../../components/header/Header";
import { Phone, LocationOn, Chat } from "@mui/icons-material";
import BreadcrumbsHeader from "../../components/header/BreadcrumbsHeader";
import { prefixer } from "stylis";
import emailjs from "emailjs-com";

import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
const AddressBlock = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 150px;

  box-shadow: 0px 25px 60px rgba(0, 65, 113, 0.05);
  border-radius: 20px;
  padding: 30px 20px 40px;
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
`;

const Title = styled(Typography)`
  && {
    font-size: 1.5rem;
  }
`;

const ContactInfo = styled(Typography)`
  && {
    font-size: 1rem;
  }
`;

// Styled components for the form and map area
const FormSection = styled.div`
  @media (min-width: 768px) {
    order: -1;
  }
`;

const MapArea = styled.div`
  margin-top: 10px;
  margin-right: 4px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }
`;
const ContactSectionWrapper = styled.div`
  position: relative;
  margin-bottom: 170px;

  @media (max-width: 991px) {
    margin-bottom: 120px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 120px;
    z-index: -1;
    background: #f6fcff;
  }
`;
const StyledHeaderCard = styled(Typography)`
  && {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2px;
    color: #333;
  }
`;
const StyledPra = styled.p`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0;
  line-height: 1.5;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;
const StyleCall = styled.a`
  font-size: 1rem;
  color: #333;
  margin-bottom: 0;
  line-height: 1.5;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
`;
const StyledWrapperMapandForm = styled.div`
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const StyledHeaderForm = styled(Typography)`
  && {
    font-size: 3.5em;
    font-weight: 700;
    margin-bottom: 10px;

    color: #333;
  }
`;
const StyledInputText = styled(TextField)`
  font-size: 18px;
  width: 100%;
  height: 60px;
  border: 1px solid rgba(0, 40, 78, 0.15);
  border-radius: 8px;
  padding: 0 20px;
`;
const StyledButton = styled(Button)`
  && {
    font-size: 17px;
    color: #fff;
    line-height: 48px;
    border-radius: 5px;
    width: 100%;
    padding: 0 40px;
    background: #913bff;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }
  &&:hover {
    background: #7a2ff1;
  }
`;
const theme = createTheme({
  direction: "rtl",
});

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const ContactUs = () => {
  const sendEmail = (e: any) => {
    e.preventDefault(); // Prevents default form submission behavior
    emailjs
      .sendForm(
        "service_cs9pleq",
        "template_ksnwvpf",
        e.target,
        "cdfqrlv42Baz6U-jk"
      )
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
          alert("הודעה נשלחה בהצלחה");
          // Handle here the success scenario (e.g., showing a success message)
        },
        (error) => {
          console.log("Failed to send email:", error.text);
          // Handle here the error scenario
        }
      );

    e.target.reset(); // Resets the form after submission
  };

  return (
    <div>
      <Header />
      <BreadcrumbsHeader />

      <ContactSectionWrapper>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <AddressBlock>
                <div className="icon d-flex align-items-center justify-content-center m-auto">
                  <img
                    src={chat}
                    alt="chat"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <StyledHeaderCard>לייב צ׳ט</StyledHeaderCard>
                <StyledPra>שירות לייב צט </StyledPra>
              </AddressBlock>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AddressBlock>
                <div className="icon d-flex align-items-center justify-content-center m-auto">
                  <img
                    src={contactMail}
                    alt="chat"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <StyledHeaderCard>יצירת קשר</StyledHeaderCard>
                <StyledPra>שלחו לנו הודעה או תתקשרו</StyledPra>
                <StyleCall href="tel:0547938785">054-7938-785</StyleCall>

                <a href="mailto:contact@thunder.co.il">שלח לנו מייל</a>
              </AddressBlock>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <AddressBlock>
                <div className="icon d-flex align-items-center justify-content-center m-auto">
                  <img
                    src={address}
                    alt="chat"
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <StyledHeaderCard> הכתובת שלנו</StyledHeaderCard>
                <StyledPra>אבן גבירול 120 תל אביב</StyledPra>
              </AddressBlock>
            </Grid>
          </Grid>
        </Container>

        <StyledWrapperMapandForm>
          <Container>
            <Grid container spacing={4}>
              <CacheProvider value={cacheRtl}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  className="d-flex order-lg-last"
                >
                  <div className="form-style-one">
                    <StyledHeaderForm
                      variant="h1"
                      className="form-title pb-40 lg-pb-20"
                    >
                      שלח הודעה.
                    </StyledHeaderForm>
                    <form
                      action="inc/contact.php"
                      id="contact-form"
                      method="post"
                      onSubmit={sendEmail}
                      data-toggle="validator"
                    >
                      <div className="messages"></div>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <StyledInputText
                            fullWidth
                            label="שם מלא"
                            name="from_name"
                            variant="outlined"
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledInputText
                            fullWidth
                            label="דואר אלקטרוני"
                            name="email"
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="הודעה"
                            name="message"
                            required
                            multiline
                            rows={4}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledButton type="submit" variant="contained">
                            שלח הודעה
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={6}
                  className="d-flex order-lg-first"
                >
                  <div className="map-area-one mt-10 me-lg-4 md-mt-50">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          style={{
                            border: "0",
                            width: "100%",
                            borderRadius: "20px",
                          }}
                          width="100%"
                          height="500"
                          src="https://maps.google.com/maps?width=600&height=400&hl=en&q=32.086121610548894,34.782157616643715&t=&z=18&ie=UTF8&iwloc=B&output=embed"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </Grid>
              </CacheProvider>
            </Grid>
          </Container>
        </StyledWrapperMapandForm>
      </ContactSectionWrapper>
    </div>
  );
};

export default ContactUs;
