import React from "react";
import styled from "styled-components";
import { Button, TextField } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import { useForm } from "react-hook-form";

import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import axios from "axios";
const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
const FormContainer = styled.div`
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const CustomTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    direction: "rtl";
    right: 0;
    border-color: ;
  }
`;
const ButtonStyle = styled(Button)`
  && {
    background-color: #760be0;
    color: #fff;
    font-weight: bold;
    font-size: 1.2em;
    &:hover {
      background-color: #760be0;
      color: #fff;
    }
  }
`;

const UserEditPortal = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const apiUrl = process.env.REACT_APP_API_URL;
  const onSubmit = async (data: any) => {
    try {
      // Replace with your actual API URL and user ID
      const response = await axios.put(`${apiUrl}/user/user/${"userId"}`, data);
      console.log("User updated:", response.data);
      alert("המשתמש עודכן בהצלחה");
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <FormContainer onSubmit={handleSubmit(onSubmit)} dir="rtl">
          <CustomTextField
            {...register("fullName")}
            label="שם מלא"
            variant="outlined"
            fullWidth
          />
          <TextField
            {...register("email")}
            label="דואר אלקטרוני"
            variant="outlined"
            fullWidth
          />
          <TextField
            {...register("phone")}
            label="מספר פלאפון"
            variant="outlined"
            fullWidth
          />
          <TextField
            {...register("password")}
            label="סיסמא"
            type="password"
            variant="outlined"
            fullWidth
          />
          <TextField
            {...register("passwordConfirm")}
            label="אימות סיסמא"
            type="password"
            variant="outlined"
            fullWidth
          />

          <ButtonStyle type="submit" variant="contained">
            שמירה שינויים
          </ButtonStyle>
        </FormContainer>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default UserEditPortal;
