import React from "react";
import SuccessPage from "./Success";
import "./styles.css";
interface IframePageProps {
  dialogUrl: string;
}
const IframePage: React.FC<IframePageProps> = ({ dialogUrl }) => {
  return (
    <div className="iframe-container">
      <iframe className="iframe" src={dialogUrl} title="Payment" />
    </div>
  );
};

export default IframePage;
