import voice from "../assets/image/icon/virtual.png";
import pepole from "../assets/image/icon/people.png";
import translate from "../assets/image/icon/artificial-intelligence.png";

interface ourServices {
  title: string;
  description: string;
  image: string;
}
interface HowITsWork {
  title: string;
  description: string;
  stepNumber: number;
}
interface PriceTable {
  title: string;
  price: string;
  whatdoyouget: {
    text: string;
    isAvailable: boolean;
  }[];
  button: string;
  link: string;
  isFeatured: boolean;
  priceToSet?: number;
  planeInEnglish?: string;
  
}

export const ourServicesData: ourServices[] = [
  {
    title: "תרגום קולי אוטומטי",
    description:
      "שירות המאפשר תרגום אוטומטי של הדיבור בווידאו שלכם לשפות שונות, באמצעות טכנולוגיית AI מתקדמת.",
    image: translate,
  },
  {
    title: "דיבוב באמצעות AI",
    description:
      "מייצרים דיבוב מדויק ומתאים לווידאו שלכם, מבטיחים שהמסר ישמע טבעי ומותאם לשפה היעד.",
    image: pepole,
  },
  {
    title: "ממשק משתמש ידידותי",
    description:
      "ניהול פשוט ויעיל של תהליכי התרגום והדיבוב, עם ממשק משתמש נוח ואינטואיטיבי.",

    image: voice,
  },
  // ניתן להוסיף עוד שירותים לפי הצורך
];

export const HowITsWorkData: HowITsWork[] = [
  {
    title: "העלאת הווידאו",
    description:
      "תחילה, אתם מעלים את סרטון הווידאו שלכם למערכת שלנו. זה יכול להיות כל סוג של קובץ וידאו.",
    stepNumber: 1,
  },
  {
    title: "זיהוי וניתוח הקול",
    description:
      " המערכת שלנו משתמשת בטכנולוגיית זיהוי קול AI כדי לנתח ולהמיר את הדיבור בווידאו לטקסט. התאמת הטקסט לקונטקסט: הAI גם מבין את הקונטקסט והמשמעות המתחבאת מאחורי המילים לשם תרגום מדויק יותר.",
    stepNumber: 2,
  },
  {
    title: "תרגום",
    description:
      " המערכת מתרגמת את הטקסט לשפה הרצויה. תהליך זה מתבצע תוך התחשבות בנונים, טעמים, ושפת סלנג, כדי להבטיח תרגום טבעי ונכון.",
    stepNumber: 3,
  },
  {
    title: "דיבוב",
    description:
      " המערכת שלנו מייצרת קול דיבוב מתוך הטקסט המתורגם. הדיבוב נעשה בקול שמתאים לטון ולרמת האנרגיה של הקול המקורי. סנכרון עם הווידאו: הדיבוב החדש מתואם בזמן לתנועות הפה ולקצב המקורי של הווידאו, כדי ליצור חוויה טבעית ומשכנעת.",
    stepNumber: 4,
  },
  {
    title: "פלט ושימוש",
    description:
      " לבסוף, אתם מקבלים את וידאו המדובב בשפה החדשה. עריכה ושיפורים: במידת הצורך, ניתן לבצע עריכות ושיפורים נוספים. התהליך הזה מאפשר לכם להרחיב את הגישה שלכם לקהלים בינלאומיים ולהפוך את התוכן שלכם לנגיש יותר, תוך שמירה על איכות ומקצועיות גבוהה.",
    stepNumber: 5,
  },
];

export const PriceTableData: PriceTable[] = [
  {
    title: "תוכנית בסיסית",
    price: "₪ 0",
    whatdoyouget: [
      { text: "סרטון אחד חינם, כל סרטון נוסף 24.90", isAvailable: true },
      { text: "כל סרטון עד 3 דקות", isAvailable: true },
      { text: "תרגום מעברית לאנגלית בלבד", isAvailable: true },
      { text: "סינכרון שפתיים", isAvailable: true },
    ],
    button: "בחר תוכנית",

    link: "/signin",
    isFeatured: false,
    priceToSet: 0,
    planeInEnglish: "basic",
  },
  {
    title: "תוכנית מתקדמת",
    price: "₪ 159",
    whatdoyouget: [
      { text: "15 סרטונים בחודש", isAvailable: true },
      { text: "כל סרטון עד 4 דקות", isAvailable: true },
      { text: "תרגום מעברית לאנגלית /ערבית/צרפתית", isAvailable: true },
      { text: "סינכרון שפתיים", isAvailable: true },
    ],
    button: "בחר תוכנית",
    link: "/signin",
    isFeatured: true,
    priceToSet: 159,
    planeInEnglish: "silver",
  },
  {
    title: "תוכנית מקצועית",
    price: "₪ 229",
    whatdoyouget: [
      { text: "30 סרטונים בחודש", isAvailable: true },
      { text: "כל סרטון עד 5 דקות", isAvailable: true },
      { text: "תרגום לכל השפות במערכת", isAvailable: true },
      { text: "סינכרון שפתיים", isAvailable: true },
    ],
    button: "בחר תוכנית",
    link: "/signin",
    isFeatured: false,
    priceToSet: 229,
    planeInEnglish: "gold",
  },
  //   {
  //     title: "תוכנית מותאמת אישית",
  //     price: "₪ 3,000",
  //     whatdoyouget: [
  //       "תרגום קולי אוטומטי",
  //       "דיבוב באמצעות AI",
  //       "ממשק משתמש ידידותי",
  //       "סנכרון עם הווידאו",
  //       "פלט ושימוש",
  //       "עריכה ושיפורים",
  //       "תרגום מדויק ומקצועי",
  //       "התאמה לקהל היעד",
  //     ],
  //     button: "בחר תוכנית",
  //     link: "/",
  //   },
];
