import React from "react"; // Import React
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Container } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import { FacebookShareButton, FacebookIcon } from "react-share";

// Define an interface for the component props
interface MediaFileItemProps {
  filename: string;
  duration: string;
  dimensions: string;
  size: string;
  frameRate: string;
  thumbnail: string;
  url: string;
}

const MediaFileItem: React.FC<MediaFileItemProps> = ({
  filename,
  duration,
  dimensions,
  size,
  frameRate,
  thumbnail,
  url,
}) => {
  console.log(url);
  const match = url.match(/([^\/_]+)\.mp4$/);

  const name = match ? `${match[1]}.mp4` : "download.mp4";

  console.log(name);

  return (
    <Paper
      sx={{
        marginTop: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
        marginBottom: "8px",
        backgroundColor: "#292A30", // Replace with your exact color
        color: "white",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          alt="Thumbnail"
          src={thumbnail}
          sx={{ width: 56, height: 56, marginRight: "8px" }}
        />
        <Grid
          container
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={3}>
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="caption">{duration}</Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="caption">איכות | {frameRate}</Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography variant="caption">מידות | {dimensions}</Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="caption">גודל | {size} </Typography>
          </Grid>
        </Grid>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a href={url} download={filename} style={{ color: "inherit" }}>
            <FileDownloadIcon
              sx={{ color: "white", marginRight: "8px", display: "flex" }}
            />
          </a>
          <DeleteIcon sx={{ color: "white", cursor: "pointer" }} />
        </div>
      </Container>
    </Paper>
  );
};

export default MediaFileItem;
