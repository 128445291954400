import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import {
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  Switch,
  Grid,
  CircularProgress,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import axios from "axios";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";

import Navbar from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import HowItsWork from "./HowItsWork";
import OurServices from "./OurServices";
import QuestionsAndAnswers from "./QuestionsAndAnswers";
import Price from "./Prices";
import ContactUs from "./ContactUs";
import BeforeAfterVideoSlider from "./Video";

import "./style.css";

interface FontSizes {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
}

const fontSize: FontSizes = {
  xs: "2.5rem",
  sm: "2.5rem",
  md: "2.6rem",
  lg: "2.8rem",
  xl: "2.8rem",
};

const Home: React.FC = () => {
  const userEmail = Cookies.get("userEmail") ?? "";
  const [isYearlyPlan, setIsYearlyPlan] = useState(false);
  const isPhoneScreen = useMediaQuery("(max-width:1100px)");
  const [videosLoaded, setVideosLoaded] = useState(false);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (isPhoneScreen) {
      setVideosLoaded(true);
      return;
    }

    const videoUrls = [
      "https://firebasestorage.googleapis.com/v0/b/mazlot.appspot.com/o/home%2Fmain.mp4?alt=media&token=3840b210-a98e-49bc-8838-2d3cdf3d5ca0",
      "https://firebasestorage.googleapis.com/v0/b/mazlot.appspot.com/o/home%2Fwave.mp4?alt=media&token=049fbc1a-5228-4d14-9905-49db13c2a0a7",
      "https://firebasestorage.googleapis.com/v0/b/mazlot.appspot.com/o/home%2Fmain2.mp4?alt=media&token=922aebcb-3a88-456e-94a3-8470fb9654a8",
    ];

    const loadVideo = (url: string) =>
      new Promise<string>((resolve, reject) => {
        const video = document.createElement("video");
        video.src = url;
        video.onloadeddata = () => resolve(url);
        video.onerror = () => reject(new Error(`Failed to load video: ${url}`));
      });

    Promise.all(videoUrls.map(loadVideo))
      .then(() => {
        setVideosLoaded(true);
      })
      .catch((error) => console.error("Error loading videos:", error));
  }, [isPhoneScreen]);

  useEffect(() => {
    const getUserId = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/users/getuser/${userEmail}`
        );
        if (response.status === 200) {
          const fullName = response.data?.user?.fullName;
          setUserName(fullName);
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    };
    getUserId();
  }, [userEmail, apiUrl]);

  const loadingIndicatorStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
  };

  const barStyle = (delay: number): React.CSSProperties => ({
    backgroundColor: "#7c4dff",
    width: "1px",
    marginLeft: "14px",
    height: "10px",
    margin: "0 2px",
    display: "inline-block",
    animation: `grow 2s ease-in-out ${delay}s infinite`,
  });

  const pricing = {
    basic: isYearlyPlan ? "₪ 0 בחודש" : "₪ 0 בחודש",
    professional: isYearlyPlan ? "₪ 1,700 בשנה" : "₪ 159 בחודש",
    master: isYearlyPlan ? "₪ 2,500 בשנה" : "₪ 229 בחודש",
  };

  const togglePlan = () => {
    setIsYearlyPlan(!isYearlyPlan);
  };

  const handleOrderNowClick = () => {
    navigate("/signin");
  };

  if (!videosLoaded && !isPhoneScreen) {
    return (
      <Container>
        <div className="loading-container">
          <CircularProgress />
        </div>
      </Container>
    );
  }

  return (
    <div>
      <section className="main_section">
        <Navbar />
        <Helmet>
          <title>THUNDER - מהפכת הדיבוב ב-AI</title>
          <meta
            name="description"
            content="גלה את THUNDER, הכלי החדשני שלנו שמשנה את כללי המשחק בתחום הדיבוב באמצעות טכנולוגיית AI."
          />
          <meta property="og:title" content="THUNDER - מהפכת הדיבוב ב-AI" />
          <meta
            property="og:description"
            content="גלה את THUNDER, הכלי החדשני שלנו שמשנה את כללי המשחק בתחום הדיבוב באמצעות טכנולוגיית AI."
          />
          <meta
            property="og:image"
            content="https://thunder.com/images/thunder-og-image.jpg"
          />
          <meta property="og:url" content="https://thunder.com/home" />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="container">
          <div className="row">
            <div style={loadingIndicatorStyle}>
              <div style={barStyle(0)}></div>
              <div style={barStyle(0.2)}></div>
              <div style={barStyle(0.4)}></div>
              <div style={barStyle(0.6)}></div>
            </div>
            <div className="main_header">
              <div className="bracket_two"></div>
              <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="main_title"
              >
                <h1 style={{ marginBottom: "1rem" }}>מהפכת הדיבוב ב-AI</h1>
                <h2> בלחיצת כפתור</h2>
                <div className="bracket"></div>
              </motion.div>
              <div className="bracket_three"></div>
              <div className="bracket_four"></div>
            </div>
          </div>
        </div>
        <>
          <link
            href="https://fonts.googleapis.com/css?family=Roboto:100"
            rel="stylesheet"
          />
          <div className="light x6" />
          <div className="light x9" />
        </>
      </section>
      <div className="section_two">
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          {isPhoneScreen ? (
            <Typography
              sx={{
                color: "white",
                fontSize: {
                  xs: "1.4rem",
                  sm: "1.5rem",
                  md: "1.6rem",
                  lg: "1.8rem",
                  xl: "1.8rem",
                },
                marginTop: "1rem",
                height: "100%",
                display: "flex",
                width: "100%",
                justifyContent: "center",
                textAlignq: "center !important",
              }}
            >
              הכירו את THUNDER, כלי דיבוב חדשני עם טכנולוגיית AI ליצירת דיבובים
              אוטומטיים, מדויקים וטבעיים לסרטים וסרטונים. פתרון מושלם למפיקים,
              יוצרי תוכן וחברות להרחבת נגישות והגעה לקהלים חדשים בעולם.
            </Typography>
          ) : (
            <Typography
              sx={{
                color: "white",
                fontSize: {
                  xs: "1.4rem",
                  sm: "1.5rem",
                  md: "1.6rem",
                  lg: "1.8rem",
                  xl: "1.8rem",
                },
                marginTop: "1rem",
                height: "100%",
              }}
            >
              הכירו את THUNDER, הכלי החדשני שלנו שמשנה את כללי המשחק בתחום
              הדיבוב. בעזרת טכנולוגיית AI מתקדמת, ניתן כעת ליצור דיבובים
              אוטומטיים, מדויקים וטבעיים ביותר לכל סרט, סרטון או הצגה. פתרון
              מושלם למפיקים, יוצרי תוכן וחברות שרוצות להרחיב את הנגישות ולהגיע
              לקהלים חדשים ברחבי העולם.
            </Typography>
          )}
        </Container>
      </div>
      <div className="section_three">
        <div className="video-container">
          <video autoPlay loop muted className="video-background ">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/mazlot.appspot.com/o/home%2Fmain.mp4?alt=media&token=3840b210-a98e-49bc-8838-2d3cdf3d5ca0"
              type="video/mp4"
            />
          </video>
          <Box className="video-text">
            <Typography
              data-aos="fade-in"
              sx={{
                color: "white",
                fontSize: {
                  xs: "1.8rem",
                  sm: "2rem",
                  md: "2.3rem",
                  lg: "3rem",
                  xl: "3rem",
                },
                height: "100%",
                zIndex: "100",
                fontWeight: "bold",
              }}
            >
              עם THUNDER תוכלו ליצור דיבובים אוטומטיים, מדויקים וטבעיים ביותר
            </Typography>
            <Button
              data-aos="fade-in"
              variant="contained"
              sx={{
                backgroundColor: "#8C20F8",
                color: "white",
                marginTop: "1rem",
                width: {
                  xs: "90%",
                  sm: "90%",
                  md: "50%",
                  lg: "50%",
                  xl: "50%",
                },
                height: "3rem",
                fontSize: "1.5rem",
              }}
              onClick={handleOrderNowClick}
            >
              התחלו עכשיו
            </Button>
          </Box>
        </div>
      </div>
      <div className="section_four">
        <Container maxWidth="xl">
          <Typography
            className="main_title_four"
            sx={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "2rem",
                lg: "2.6rem",
                xl: "3rem",
              },
            }}
          >
            THUNDER מביאה את טכנולוגיית הAI לעולם התרגום הדיבוב!
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "2rem",
              maxHeight: "100%",
            }}
          >
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} sm={6} data-aos="fade-up" sx={{ width: "0%" }}>
                <Grid container spacing={1} direction="row">
                  <Grid item xs={12} sm={12} data-aos="fade-up">
                    <Card
                      sx={{
                        height: 300,
                        backgroundColor: "black",
                      }}
                      data-aos="fade-up"
                    >
                      <CardContent
                        sx={{
                          color: "white",
                          height: 150,
                          width: "37%",
                          fontSize: "1.4em",
                          padding: "1rem",
                          zIndex: 100,
                          position: "absolute",
                          "@media (max-width: 600px)": {
                            width: "95% !important",
                          },
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          sx={{
                            fontSize: "1.5em",
                            "@media (max-width: 600px)": {
                              fontSize: "1.2em",
                            },
                          }}
                        >
                          תרגום קולי אוטומטי
                        </Typography>
                        <Typography
                          variant="body2"
                          fontSize={"1.1em"}
                          sx={{
                            marginTop: "1rem",
                            fontSize: "1em",
                            padding: "1rem",
                          }}
                        >
                          שירות המאפשר תרגום אוטומטי של הדיבור בווידאו שלכם
                          לשפות שונות, באמצעות טכנולוגיית AI מתקדמת.
                        </Typography>
                      </CardContent>
                      <video
                        autoPlay
                        loop
                        muted
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          zIndex: 0,
                        }}
                        className="video-background_second"
                      >
                        <source
                          src="https://firebasestorage.googleapis.com/v0/b/mazlot.appspot.com/o/home%2Fwave.mp4?alt=media&token=049fbc1a-5228-4d14-9905-49db13c2a0a7"
                          type="video/mp4"
                        />
                      </video>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} data-aos="fade-up">
                    <Card
                      sx={{
                        maxWidth: "100%",
                        maxHeight: 345,
                        backgroundColor: "black",
                        color: "white",
                      }}
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <CardContent>
                        <Typography
                          gutterBottom
                          variant="h4"
                          component="div"
                          sx={{
                            fontSize: "1.5em",
                            "@media (max-width: 600px)": {
                              fontSize: "1.5em",
                            },
                          }}
                        >
                          ממשק משתמש ידידותי
                        </Typography>
                        <Typography
                          variant="body1"
                          color="white"
                          sx={{ fontSize: "1.1em" }}
                        >
                          ניהול פשוט ויעיל של תהליכי התרגום והדיבוב, עם ממשק
                          משתמש נוח ואינטואיטיבי.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} data-aos="fade-up">
                <Card
                  sx={{ height: "100%" }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <video
                    autoPlay
                    loop
                    muted
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    className="video-background_second"
                  >
                    <source
                      src="https://firebasestorage.googleapis.com/v0/b/mazlot.appspot.com/o/home%2Fmain2.mp4?alt=media&token=922aebcb-3a88-456e-94a3-8470fb9654a8"
                      type="video/mp4"
                    />
                  </video>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Container>
        <div className="boxwave">
          <div className="wave -one"></div>
        </div>
      </div>
      <div className="section_five">
        <Container
          maxWidth="xl"
          sx={{
            paddingBottom: "10rem",
            "@media (max-width: 600px)": {
              marginTop: "23rem",
            },
          }}
        >
          <div className="price_table">
            <div className="toggle_plan_section">
              <Container
                sx={{
                  color: "white",
                  fontSize: {
                    xs: "1.5rem",
                    sm: "1.5rem",
                    md: "1.5rem",
                    lg: "2.5rem",
                    xl: "2.5rem",
                  },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-end",
                }}
              >
                <Typography sx={{ marginRight: "8px", fontSize: "1.9rem" }}>
                  שנתי
                </Typography>
                <Switch
                  checked={isYearlyPlan}
                  onChange={togglePlan}
                  name="planToggle"
                  color="primary"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transform: "scale(1.3)",
                    "$ .MuiSwitch-root": {
                      width: "3.5rem !important",
                      height: "2rem",
                    },
                    "& .MuiSwitch-thumb": {
                      backgroundColor: "white",
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: "#8c91e8",
                    },
                    "& .Mui-checked": {
                      color: "white",
                    },
                    "& .Mui-checked + .MuiSwitch-track": {
                      backgroundColor: "#8c91e8",
                    },
                  }}
                />
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1.9rem",
                    marginLeft: "8px",
                  }}
                >
                  חודשי
                </Typography>
              </Container>
            </div>
            {!isPhoneScreen ? (
              <div className="cards_price">
                <div className="card shadow">
                  <ul>
                    <li className="pack">תוכנית בסיסית</li>
                    <li id="basic" className="pricenoavtive bottom-bar">
                      {pricing.basic}
                    </li>
                    <li className="bottom-bar">סרטון אחד חינם*</li>
                    <li className="bottom-bar">כל סרטון עד 3 דקות</li>
                    <li className="bottom-bar">תרגום מעברית לאנגלית בלבד</li>
                    <li className="bottom-bar"> סינכרון שפתיים</li>
                    <li>
                      <button className="btn" onClick={handleOrderNowClick}>
                        הזמן עכשיו
                      </button>
                    </li>
                  </ul>
                  <p style={{ display: "flex", justifyContent: "center" }}>
                    *כל סרטון נוסף 24.90
                  </p>
                </div>
                <div className="card active">
                  <ul>
                    <li className="pack">תוכנית מתקדמת</li>
                    <li id="professional" className="price">
                      {pricing.professional}
                    </li>
                    <li className="bottom-bar">15 סרטונים בחודש</li>
                    <li className="bottom-bar">כל סרטון עד 4 דקות</li>
                    <li className="bottom-bar">
                      תרגום מעברית לאנגלית /ערבית/צרפתית
                    </li>
                    <li className="bottom-bar"> סינכרון שפתיים</li>
                    <li>
                      <button
                        className="btn active-btn"
                        onClick={handleOrderNowClick}
                      >
                        הזמן עכשיו
                      </button>
                    </li>
                  </ul>
                </div>
                <div className="card shadow">
                  <ul>
                    <li className="pack">תוכנית מקצועית</li>
                    <li id="master" className="pricenoavtive bottom-bar">
                      {pricing.master}
                    </li>
                    <li className="bottom-bar">30 סרטונים בחודש</li>
                    <li className="bottom-bar">כל סרטון עד 5 דקות</li>
                    <li className="bottom-bar">תרגום לכל השפות במערכת</li>
                    <li className="bottom-bar"> סינכרון שפתיים</li>
                    <li>
                      <button className="btn" onClick={handleOrderNowClick}>
                        הזמן עכשיו
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="cards_price_response">
                <Carousel
                  autoPlay={false}
                  animation="slide"
                  navButtonsAlwaysInvisible={true}
                  indicators={true}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="card shadow">
                      <ul>
                        <li className="pack">תוכנית בסיסית</li>
                        <li id="basic" className="pricenoavtive bottom-bar">
                          {pricing.basic}
                        </li>
                        <li className="bottom-bar">סרטון אחד חינם*</li>
                        <li className="bottom-bar">כל סרטון עד 3 דקות</li>
                        <li className="bottom-bar">
                          תרגום מעברית לאנגלית בלבד
                        </li>
                        <li className="bottom-bar"> סינכרון שפתיים</li>
                        <li>
                          <button className="btn" onClick={handleOrderNowClick}>
                            הזמן עכשיו
                          </button>
                        </li>
                      </ul>
                      <p style={{ display: "flex", justifyContent: "center" }}>
                        *כל סרטון נוסף 24.90
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="card active">
                      <ul>
                        <li className="pack">תוכנית מתקדמת</li>
                        <li id="professional" className="price bottom-bar">
                          {pricing.professional}
                        </li>
                        <li className="bottom-bar">15 סרטונים בחודש</li>
                        <li className="bottom-bar">כל סרטון עד 4 דקות</li>
                        <li className="bottom-bar">
                          תרגום מעברית לאנגלית /ערבית/צרפתית
                        </li>
                        <li className="bottom-bar"> סינכרון שפתיים</li>
                        <li>
                          <button
                            className="btn active-btn"
                            onClick={handleOrderNowClick}
                          >
                            הזמן עכשיו
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="card shadow">
                      <ul>
                        <li className="pack">תוכנית מקצועית</li>
                        <li id="master" className="pricenoavtive bottom-bar">
                          {pricing.master}
                        </li>
                        <li className="bottom-bar">30 סרטונים בחודש</li>
                        <li className="bottom-bar">כל סרטון עד 5 דקות</li>
                        <li className="bottom-bar">תרגום לכל השפות במערכת</li>
                        <li className="bottom-bar"> סינכרון שפתיים</li>
                        <li>
                          <button className="btn" onClick={handleOrderNowClick}>
                            הזמן עכשיו
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Carousel>
              </div>
            )}
          </div>
        </Container>
      </div>
      <div className="section_six">
        <QuestionsAndAnswers />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
