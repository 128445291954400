// HiddenPage.tsx
import React, { useEffect, useState } from "react";
import axios from "axios";

const HiddenPage: React.FC = () => {
  const [response, setResponse] = useState<string>("");

  useEffect(() => {
    const baseURL = process.env.REACT_APP_API_URL;

    // Make an HTTP GET request to the server route
    axios
      .get(`${baseURL}/pay/get-low-profile-indicator`)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((error) => {
        console.error("HTTP Request Error:", error);
        setResponse("Internal Server Error");
      });
  }, []);

  return (
    <div>
      <h2>Hidden Page</h2>
      <div>
        <h3>Response from Server:</h3>
        <p>{response}</p>
      </div>
      {/* Add any additional content as needed */}
    </div>
  );
};

export default HiddenPage;
