import React from "react";
import { Container, Typography, TypographyProps, Box } from "@mui/material";
import styled from "styled-components";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const StyledPageWrapper = styled.div`
  background: rgb(16, 9, 25);
  background: radial-gradient(
    circle,
    rgba(16, 9, 25, 1) 0%,
    rgba(21, 11, 44, 1) 100%
  );
  min-height: 140vh; // Ensure it covers the whole viewport height
  color: white; // Example text color for better visibility on the dark background
`;
const SubTitle = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;
const VisionBox = styled(Box)`
  margin-top: 24px;
  background-color: #f0f0f7;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const VisionTitle = styled(Typography)<TypographyProps>`
  && {
    font-weight: bold;
    color: #333;
    margin-bottom: 16px; // Adds spacing between title and paragraph
  }
`;

const VisionText = styled(Typography)<TypographyProps>`
  && {
    font-size: 1.4rem !important;
    color: #666 !important;
    line-height: 1.6 !important;
    margin-bottom: 50px !important;
  }
`;
const About: React.FC = () => {
  return (
    <StyledPageWrapper>
      <Header />
      <Container maxWidth="lg" sx={{ my: 4 }}>
        <Typography variant="h3" gutterBottom component="div">
          עלינו
        </Typography>
        <SubTitle paragraph>
          המציע מבט מעמיק על החזון, הטכנולוגיה, והאנשים שמאחורי המהפכה בעולם
          הדיבוב.
        </SubTitle>

        <VisionBox>
          <VisionTitle variant="h4" component="div">
            החזון שלנו
          </VisionTitle>
          <VisionText paragraph>
            בחברתנו, אנו מאמינים בכוחה של חדשנות וטכנולוגיה לשנות תחומים ולהנגיש
            אותם לכולם. חזוננו הוא לשבור את המחסומים הלשוניים והתרבותיים בעזרת
            טכנולוגיית AI מתקדמת, המאפשרת יצירת דיבובים אוטומטיים, מדויקים
            וטבעיים ביותר. אנו שואפים להפוך כל סרט, סדרה, סרטון או הצגה לנגישים
            לקהל גלובלי, מבלי להתפשר על איכות הדיבוב והתרגום.
          </VisionText>
          <VisionTitle variant="h4" component="div">
            הטכנולוגיה שלנו
          </VisionTitle>
          <VisionText paragraph>
            הטכנולוגיה שלנו מבוססת על למידת מכונה ובינה מלאכותית מתקדמת, המאפשרת
            לנו להבין ולזהות את הטונים והמשמעויות הטמונים בטקסט המדובב. זה מאפשר
            לנו ליצור דיבובים שנשמעים טבעיים ומדויקים, תוך שמירה על האיכות והרגש
            של המקור. הפלטפורמה שלנו מסוגלת לעבוד עם מגוון רחב של שפות ולהתאים
            את עצמה לצרכים ספציפיים של כל פרויקט.
          </VisionText>
          <VisionTitle variant="h4" component="div">
            האנשים מאחורי הקלעים
          </VisionTitle>
          <VisionText paragraph>
            צוותנו מורכב ממומחי AI, מהנדסי תוכנה, מומחי תוכן ולשון, ומנהלי
            פרויקטים שמחויבים למצוינות. אנו מאמינים בעבודה צוותית, חדשנות ובכך
            שכל קול צריך להישמע. אנו פועלים יחד כדי להביא את הטכנולוגיה הטובה
            ביותר, השירות הטוב ביותר והתוצאות הטובות ביותר ללקוחותינו.
          </VisionText>
          <VisionTitle variant="h4" component="div">
            התחייבות לאיכות
          </VisionTitle>
          <VisionText paragraph>
            אנו מחויבים לאיכות בכל שלב ושלב. מהמחקר הראשוני ועד לפיתוח הפרויקט
            הסופי, אנו מבטיחים שכל דיבוב יעבור בדיקות איכות קפדניות כדי להבטיח
            את המקצועיות והטבעיות הגבוהה ביותר.
          </VisionText>
          <VisionTitle variant="h4" component="div">
            קדימה{" "}
          </VisionTitle>
          <VisionText paragraph>
            העתיד נראה מבטיח, ואנו ממשיכים לחדש ולשפר את הטכנולוגיות שלנו כדי
            להבטיח שהן מספקות את הפתרונות המתקדמים ביותר לצרכים המשתנים של עולם
            המדיה והתוכן. ביחד, אנו יוצרים עתיד שבו כל אחד יכול ליהנות מתוכן בכל
            שפה, בכל זמן ובכל מקום. אנו מזמינים אתכם להצטרף אלינו במסע זה, לגלות
            יותר על האופן שבו אנו עובדים ולגלות איך הטכנולוגיה שלנו יכולה לעזור
            לכם להגיע לקהלים חדשים ולשבור מחסומים. בואו ניצור יחד את המהפכה
            בעולם הדיבוב.
          </VisionText>
        </VisionBox>

        {/* Use StyledSection for other sections as well */}
      </Container>
      <Footer />
    </StyledPageWrapper>
  );
};

export default About;
