// authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types/types";
import Cookies from "js-cookie";

// Define the shape of your authentication-related state
export interface AuthState {
  user: User; // Update the user property to use SignInFormInputs
  
}

// Define the initial state with default values
const initialState: AuthState = {
  user: {
    email: "",
    fullName: "",
    remember: false,
    isVerified: false,
    agreeToTerms: false,
    selectedProgram: "",
  },
};

// Create the authSlice
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Define the setUserData reducer to update the state with the payload data
    setUserData: (state, action: PayloadAction<User>) => {
      const userData = action.payload;
      // Set user data in state
      state.user = userData;
      // Save some user data to cookies for persistent authentication
      Cookies.set("userEmail", userData.email, { expires: 7 }); // Expires in 7 days
      // You can set more user details in cookies as needed
    },
    clearUserData: (state) => {
      // Clear user data from state
      state.user = initialState.user;
      // Remove user data from cookies
      Cookies.remove("userEmail");
      // Remove more user details from cookies as needed
    },
  },
});

// Export the action creator and reducer
export const { setUserData, clearUserData } = authSlice.actions;
export default authSlice.reducer;
