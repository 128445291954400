import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { StackWrapper } from "./Header.style";
import { Container } from "@mui/system";
import shapeone from "../../assets/shape/shape_39.svg";
import shapetwo from "../../assets/shape/shape_38.svg";
import styled, { keyframes } from "styled-components";

const StyledBreadcrumbs = styled(Breadcrumbs)`
  && {
    color: #555; // Example color
    font-size: 0.875em; // Example font size
    // Add more styles here
  }
`;
const jumpTwoAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); // Change the value for the desired effect
  }
`;

// Define the keyframes for the jumpThree animation
// This is just a placeholder. You'll need to define the actual animation frames.
const jumpThreeAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1); // For example, slightly scale up
  }
`;
const StyledLink = styled(Link)`
  && {
    color: #0066cc; // Example color for links
    &:hover {
      text-decoration: underline;
      color: #0055a5; // Example hover color
    }
    // Add more styles here
  }
`;
const StyledShape = styled.img`
  position: absolute;
  z-index: 2;
  top: 100px;
  animation: ${jumpTwoAnimation} 3s infinite linear,
    ${jumpThreeAnimation} 3s infinite linear;
  // Add additional styling as needed
`;

const StyledShapeTwo = styled.img`
  position: absolute;
  z-index: 2;
  top: 100px;
  left: 30px;
  bottom: 20px;
  animation: ${jumpTwoAnimation} 3s infinite linear,
    ${jumpThreeAnimation} 3s infinite linear;
  // Add additional styling as needed
`;
const StyledTypography = styled(Typography)`
  && {
    color: #333; // Example color for the current page
    // Add more styles here
  }
`;
const StyledWrapperHeader = styled.div`
  font-size: 50px;
  letter-spacing: -0.8px;

  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
`;
const StyledHeader = styled.h2`
  margin: 0;
`;

const StyledStack = styled(Stack)`
  && {
    background: #f6fcff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 83px 0 115px;
    overflow: hidden;
  }
`;
function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function BreadcrumbsHeader() {
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/"
      onClick={handleClick}
    >
      בית
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
      צור קשר
    </Link>,
  ];

  return (
    <div>
      <StyledShape src={shapeone} alt="shape" />

      <StyledStack spacing={2}>
        <Container>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <StyledWrapperHeader>
              <StyledHeader>צור קשר</StyledHeader>
            </StyledWrapperHeader>
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="small"
                  sx={{
                    color: "#760be0",
                    transform: "rotate(180deg)",
                  }}
                />
              }
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </div>
        </Container>
      </StyledStack>
      <StyledShapeTwo src={shapetwo} alt="shape" />
    </div>
  );
}
