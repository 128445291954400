import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <p>
        הקדמה
        <br />
        ברוכים הבאים אל Thunder.co.il
        <br />
        Thunder.co.il ("אנו", "אנחנו" או "שלנו") מנהלת
        https://www.thunder.co.il/ (להלן - "השירות").
        <br />
        מדיניות הפרטיות שלנו שולטת בביקור שלך ב-https://www.thunder.co.il/,
        ומסבירה כיצד אנו אוספים, מגן ומחשפים מידע שמתקבל משימושך בשירות שלנו.
        <br />
        אנו משתמשים בנתונים שלך כדי לספק ולשפר את השירות. בשימוש בשירות, אתה
        מסכים לאיסוף ולשימוש במידע על פי מדיניות זו. אלא אם כן נקבע אחרת
        במדיניות פרטיות זו, המונחים במדיניות זו משמעותם הם כפי שנקבע בתנאי
        השימוש שלנו.
        <br />
        <br />
        הגדרות
        <br />
        השירות מתייחס לאתר https://www.thunder.co.il/ המופעל על ידי
        Thunder.co.il
        <br />
        נתונים אישיים הם נתונים הקשורים לאדם חי שניתן לזהות אותו מהנתונים הללו
        (או מהנתונים הללו ומידע אחר הנמצא ברשותנו או שעשוי להיכנס לרשותנו).
        <br />
        נתוני שימוש הם נתונים הנאספים באופן אוטומטי הנוצרים על ידי השימוש בשירות
        או מתשתיות השירות עצמם (לדוגמה, משך ביקור בדף).
        <br />
        עוגיות הן קבצים קטנים המאוחסנים במכשיר שלך (מחשב או מכשיר נייד).
        <br />
        בעל מערכת המידע הוא אדם טבעי או פרטי אשר (באופן בודד, משותף או בשיתוף עם
        פרטים אחרים) קובע את מטרות העיבוד של נתונים אישיים ואת הדרך בה הם
        מעובדים או יעובדו. לצורך מדיניות הפרטיות הזו, אנו בעלי המערכת של נתוני
        המשתמש שלך.
        <br />
        עובדי נתונים (או ספקי השירות) מתייחסים לכל אדם טבעי או פרטי העובד את
        הנתונים מטעם בעל מערכת המידע. אנו עשויים להשתמש בשירותי ספקים שונים כדי
        לעבד את הנתונים שלך בצורה יעילה יותר.
        <br />
        נושא הנתונים הוא כל אדם חי הנוגע לו נתונים אישיים.
        <br />
        המשתמש הוא האדם המשתמש בשירות שלנו. המשתמש תואם לנושא הנתונים, הפרט
        הנוגע לו נתונים אישיים.
        <br />
        <br />
        איסוף ושימוש במידע
        <br />
        אנו אוספים מספר סוגים שונים של מידע למטרות שונות כדי לספק ולשפר את
        השירות שלנו לך.
        <br />
        <br />
        סוגי המידע שנאסף
        <br />
        מידע אישי
        <br />
        בעת השימוש בשירות שלנו, עשויים לבקש ממך לספק מידע אישי מזהה מסוים שיכול
        לשמש ליצירת קשר או לזיהוי שלך ("מידע אישי"). מידע אישי מזהה עשוי לכלול,
        אך לא נוגב ל:
        <br />
        כתובת דוא"ל
        <br />
        עוגיות ומידע על שימוש
        <br />
        אנו עשויים להשתמש במידע האישי שלך כדי ליצור עמך קשר באמצעות ידיעון,
        חומרי שיווק או פרסומות ומידע אחר העשוי לעניין אותך. באפשרותך לבטל את
        קבלת כל אחד או כל אחת מההודעות הללו מאיתנו על ידי עקיפה דרך הקישור
        לביטול ההרשמה.
        <br />
        מידע על שימוש
        <br />
        אנו עשויים גם לאסוף מידע שהדפדפן שלך שולח בכל פעם שאתה מבקר בשירות שלנו
        או כאשר אתה גושש את השירות באמצעות מכשיר נייד ("מידע על השימוש").
        <br />
        מידע על השימוש עשוי לכלול פרטים כמו כתובת הפרוטוקול של האינטרנט של המחשב
        שלך (לדוג', כתובת IP), סוג הדפדפן, גרסת הדפדפן, העמודים בשירות שלנו
        שביקרת בהם, הזמן והתאריך שביקרת בהם, הזמן שבילית בהם, זיהויים ייחודיים
        של המכשיר ומידע דיאגנוסטי אחר.
        <br />
        כאשר אתה גושש את השירות באמצעות מכשיר נייד, מידע על השימוש זה עשוי לכלול
        פרטים כמו סוג המכשיר הנייד שאתה משתמש, זיהוי ייחודי של המכשיר הנייד שלך,
        כתובת ה-IP של מכשיר הנייד שלך, מערכת ההפעלה של המכשיר הנייד שלך, סוג
        דפדפן האינטרנט הנייד שאתה משתמש, זיהויים ייחודיים של המכשיר ומידע
        דיאגנוסטי אחר.
        <br />
        מידע מיקום
        <br />
        אנו עשויים להשתמש ולאחסן מידע אודות המיקום שלך אם תענה לנו ברשות לעשות
        כן ("מידע מיקום"). אנו משתמשים במידע זה כדי לספק תכונות של השירות שלנו,
        לשפר ולהתאים את השירות שלנו.
        <br />
        באפשרותך לאפשר או למנוע שירותי מיקום כאשר אתה משתמש בשירות שלנו בכל עת
        על ידי הגדרות המכשיר שלך.
        <br />
        מידע עוגיות מעקב
        <br />
        אנו משתמשים בעוגיות ובטכנולוגיות מעקב דומות למעקב אחר הפעילות בשירות
        שלנו ואנו שומרים מידע מסוים.
        <br />
        עוגיות הן קבצים עם כמות קטנה של מידע העשויים לכלול זיהוי ייחודי אנונימי.
        עוגיות נשלחות לדפדפן שלך מאתר ומאוחסנות על המכשיר שלך. טכנולוגיות מעקב
        אחרות משמשות גם כמו ביקורות, תגיות וסקריפטים כדי לאסוף ולעקוב אחר מידע
        וכדי לשפר ולנתח את השירות שלנו.
        <br />
        באפשרותך להורות לדפדפן שלך לסרב לכל העוגיות או להצביע כאשר עוגיה נשלחת.
        אך, אם לא תקבל עוגיות, עשויים להיות מגבלות על השירות שלנו.
        <br />
        דוגמאות לעוגיות שאנו משתמשים בהן:
        <br />
        עוגיות סשן: אנו משתמשים בעוגיות סשן להפעלת השירות שלנו.
        <br />
        עוגיות עדיפות: אנו משתמשים בעוגיות עדיפות לזכור את העדפותיך והגדרות
        שונות.
        <br />
        עוגיות אבטחה: אנו משתמשים בעוגיות אבטחה לצורך אבטחה.
        <br />
        עוגיות פרסום: עוגיות פרסום משמשות להצגת מודעות שעשויות להיות רלוונטיות
        עבורך ועבור התחומים שעשויים לעניין אותך.
        <br />
        <br />
        שימוש במידע
        <br />
        THUNDER.CO.IL משתמשת במידע שנאסף למטרות שונות:
        <br />
        לספק ולשמור על השירות שלנו;
        <br />
        להודיע לך על שינויים בשירות שלנו;
        <br />
        לאפשר לך להשתתף בתכונות אינטראקטיביות של השירות שלנו כאשר בוחר לעשות כך;
        <br />
        לספק תמיכה לקוחות;
        <br />
        לאסוף נתונים ומידע מערכתי שיכול לשרת לנו לשיפור השירות שלנו;
        <br />
        למעקב אחר השימוש בשירות שלנו;
        <br />
        לזיהוי, למניעה ולטיפול בבעיות טכניות;
        <br />
        למילוי כל מטרה אחרת שאתה מספק את המידע עבורה;
        <br />
        למילוי התחייבויותינו ולאכיפת זכויותינו שעולות מחוזי בינינו, כולל חיוב
        ואיסוף;
        <br />
        לספק הו`דעות אודות חשבון ו/או מינוי שלך, כולל הודעות על תפוגה וחידוש,
        הוראות בדוא"ל וכו';
        <br />
        לספק לך חדשות, הצעות מיוחדות ומידע כללי אודות סחורות, שירותים ואירועים
        אחרים שאנו מציעים שהם דומים לאלה שכבר רכשת או ששאלת עליהם אלא אם בחרת
        שלא לקבל מידע כזה;
        <br />
        בכל דרך אחרת שאנו יכולים לתאר בעת שמספקים את המידע;
        <br />
        לכל מטרה אחרת עם ההסכמה שלך.
        <br />
        <br />
        שמירת המידע
        <br />
        אנו נשמור על נתוני הפרטיות שלך רק כפי שנדרש למטרות המפורטות במדיניות
        הפרטיות זו. אנו נשמור ונשתמש בנתוני הפרטיות שלך למידה הנדרשת כדי לעמוד
        בהתחייבויות המשפטיות שלנו (לדוגמה, אם אנחנו נדרשים לשמור את נתוניך כדי
        לעמוד בחוקים רלוונטיים), לפתור סכסוכים ולאכוף את הסכמים והמדיניות
        המשפטיים שלנו.
        <br />
        נשמור גם על נתוני השימוש לצורך ניתוח פנימי. נתוני השימוש בדרך כלל
        מתוייגים לתקופה קצרה יותר, אלא כאשר הנתונים הללו משמשים לחיזוק האבטחה או
        לשיפור פונקציונליות השירות שלנו, או כאשר יש חובה משפטית לשמור נתונים אלו
        לתקופות ארוכות יותר.
        <br />
        <br />
        העברת נתונים
        <br />
        המידע שלך, כולל נתונים אישיים, עשוי להיעבר ולהישמר על מחשבים הממוקמים
        מחוץ למדינה, מחוז, ארץ או סמכויות ממשלתיות אחרות שבהן חוקי ההגנה על
        הנתונים עשויים להיות שונים מאלה שבמדינתך.
        <br />
        אם אתה ממוקם מחוץ לארצות הברית ובחרת לספק מידע לנו, יש לך לקחת בחשבון כי
        אנו מעבירים את המידע, כולל נתונים אישיים, לארצות הברית ומעבדים אותו שם.
        <br />
        ההסכמה שלך למדיניות הפרטיות הזו והגשת המידע מהווים את ההסכמה שלך להעברה
        זו.
        <br />
        Thunder.co.il תקח את כל השלבים הנדרשים במידה הסבירה על מנת להבטיח
        שהנתונים שלך ייעבדו באופן מאובטח ובהתאם למדיניות הפרטיות הזו. לא תתבצע
        העברה של הנתונים האישיים שלך לארגון או למדינה מלבד אם יהיו במקום כלים
        מתאימים, כולל הגנה על הנתונים שלך ומידע אישי אחר.
        <br />
        <br />
        חשיפת נתונים
        <br />
        אנו עשויים לחשוף מידע אישי שאנו אוספים או שאתה סופק:
        <br />
        חשיפה לאכיפת החוק.
        <br />
        בכמה מקרים, עשויים להתבקש לחשוף את הנתונים האישיים שלך אם יתבקש כך על פי
        החוק או בתגובה לבקשות תקפות מסמכי רשויות ציבוריות.
        <br />
        עסקאות מסחריות.
        <br />
        אם אנחנו או החברות המפיקות שלנו מעורבות במיזוג, רכישה או מכירת נכסים,
        עשויים להעביר את נתוני האישיים שלך.
        <br />
        מקרים אחרים. ייתכן שנחשוף את המידע שלך גם:
        <br />
        לחברות המפיקות והקשות שלנו;
        <br />
        לקבלנים, ספקים שירותים וצדדים שלישיים אחרים שאנו משתמשים בהם לתמיכה בעסק
        שלנו;
        <br />
        למלא את המטרה שבשבילה סיפקת את המידע;
        <br />
        עם ההסכמה שלך במקרים אחרים;
        <br />
        אם אנו מאמינים שחשיפה היא נחוצה או מתאימה להגנת הזכויות, הרכוש או
        הבטיחות של החברה, לקוחותינו או לאחרים.
        <br />
        אבטחת המידע
        <br />
        האבטחה של המידע שלך חשובה לנו, אך חשוב לזכור כי אין שיטת העברת מידע על
        האינטרנט או שיטת אחסון אלקטרוני שהיא מאובטחת ב-100%. בעוד שאנו מתאמצים
        להשתמש באמצעים מקובלים מסחרית על מנת להגן על הנתונים האישיים שלך, אנו לא
        יכולים להבטיח את האבטחה המוחלטת שלה.
        <br />
        <br />
        זכויות הגנת המידע שלך לפי תקנות הגורפים הכלליות להגנת הנתונים (GDPR)
        <br />
        אם אתה תושב באיחוד האירופי (EU) או באזור הכלכלי האירופי (EEA), יש לך
        זכויות ספציפיות להגנת הנתונים, המכוסות על פי GDPR. – לפרטים נוספים
        בקישור: https://eur-lex.europa.eu/eli/reg/2016/679/oj
        <br />
        אנו מתאמצים לקדם צעדים סבירים שיאפשרו לך לתקן, לשנות, למחוק או להגביל את
        השימוש בנתונים האישיים שלך.
        <br />
        אם ברצונך לקבל מידע על הנתונים האישיים שאנו מחזיקים עליך ולבקש למחוק
        אותם מהמערכת שלנו, אנא שלח אימייל לכתובת info@thunder.co.il.
        <br />
        במקרים ספציפיים, יש לך את הזכויות להגנת הנתונים הבאות:
        <br />
        הזכות לגישה, לעדכון או למחיקת המידע שיש לנו עליך;
        <br />
        הזכות לתיקון. יש לך זכות לתקן את המידע שלך אם המידע אינו מדוייק או שהוא
        לא שלם;
        <br />
        הזכות להתנגדות. יש לך זכות להתנגד לעיבוד הנתונים האישיים שלך;
        <br />
        הזכות להגבלה. יש לך זכות לבקש מאיתנו להגביל את העיבוד של מידע האישי שלך;
        <br />
        הזכות לניידות המידע. יש לך זכות לקבל עותק של המידע האישי שלך בתבנית
        מובנית, קריאה למכונה ובפורמט נפוץ;
        <br />
        הזכות לשלול את הסכמתך. יש לך גם זכות לשלול את הסכמתך בכל עת בה העסקתנו
        על סמך הסכמתך לעיבוד המידע האישי שלך;
        <br />
        נא לשים לב כי ייתכן ונבקש ממך לאמת את זהותך לפני תגובה לבקשות כאלה. יש
        לשים לב, כי ייתכן ולא נוכל לספק את השירות ללא חלק מהנתונים הנדרשים.
        <br />
        יש לך זכות להגיש תלונה לרשות להגנת הנתונים בנוגע לאיסוף ושימוש שלנו
        בנתונים האישיים שלך. למידע נוסף, יש ליצור קשר עם רשות ההגנה על הנתונים
        המקומית באזור הכלכלי האירופי (EEA).
        <br />
        <br />
        זכויות הגנת המידע שלך לפי חוק הפרטיות בקליפורניה (CalOPPA)
        <br />
        CalOPPA הוא החוק הראשון בארצות הברית שדורש מאתרי עסקיים ושירותי מקוונים
        לפרסם מדיניות פרטיות. ההשפעה של החוק נמשכת הרבה מעבר לקליפורניה, ודורש
        מאדם או חברה בארצות הברית (ואולי אף ברחבי העולם) שמנהל אתרי אינטרנט
        המאספים מידע אישי מקונסומרים מקליפורניה לפרסם מדיניות פרטיות בולטת באתר
        שלו המציינת בדיוק את המידע שנאסף ואת אלה שאיתם המידע משותף, ולהיתקע
        במדיניות זו. – למידע נוסף:
        https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
        <br />
        בהתאם ל-CalOPPA, אנו מסכימים לכך:
        <br />
        משתמשים יכולים לבקר באתר שלנו באופן אנונימי;
        <br />
        קישור המדיניות שלנו כולל את המילה "פרטיות" וניתן למצוא אותו בקלות בדף
        המצוי למעלה, בדף הבית של האתר שלנו;
        <br />
        המשתמשים יקבלו הודעה על כל שינוי במדיניות הפרטיות בדף מדיניות הפרטיות
        שלנו;
        <br />
        המשתמשים יכולים לשנות את המידע האישי שלהם על ידי שליחת אימייל אלינו
        לכתובת info@thunder.co.il.
        <br />
        מדיניות שלנו בנושא "Do Not Track" (אל תעקוב):
        <br />
        אנו מכבדים את אותות Do Not Track ולא מעקבים, לא מטמיעים עוגיות או
        משתמשים בפרסום כאשר יש מנגנון דפדפן Do Not Track בשימוש. Do Not Track
        הוא העדפה שאפשר להגדיר בדפדפן האינטרנט שלך כדי ליידע את אתרי האינטרנט
        שאינך רוצה להימצא במעקב.
        <br />
        ניתן להפעיל או להשבית את Do Not Track על ידי ביקור בדף ההעדפות או
        ההגדרות בדפדפן האינטרנט שלך.
        <br />
        זכויות הגנת הנתונים שלך על פי חוק הפרטיות של צרפת (CCPA)
        <br />
        אם אתה תושב קליפורניה, יש לך זכות לדעת אילו נתונים אנו אוספים עליך, לבקש
        למחוק את הנתונים שלך ולא למכור (לשתף) אותם. כדי להפעיל את זכויות הגנת
        הנתונים שלך, תוכל להגיש בקשות מסוימות ולבקש מאיתנו:
        <br />
        אילו מידע אישי יש לנו עליך. אם אתה מבקש את זה, נחזיר לך:
        <br />
        את קטגוריות המידע האישי שאספנו עליך.
        <br />
        את קטגוריות מקורות ממנם אנו אוספים את המידע האישי שלך.
        <br />
        המטרה העסקית או המסחרית לאיסוף או למכירה של המידע האישי שלך.
        <br />
        את הקטגוריות של צדדים שלישיים עם אילם אנו משתפים מידע אישי.
        <br />
        הפרטים האישיים הספציפיים שאספנו עליך.
        <br />
        רשימה של קטגוריות של מידע אישי שמכרנו, יחד עם הקטגוריה של כל חברה אחרת
        שמכרנו לה. אם לא מכרנו את המידע האישי שלך, נודיע לך על כך.
        <br />
        רשימה של קטגוריות של מידע אישי שחשפנו למטרת עסקית, יחד עם הקטגוריה של כל
        חברה אחרת ששיתפנו אותה.
        <br />
        שים לב, יש לך זכות לבקש מאיתנו לספק לך את המידע הזה עד שתי פעמים בתקופה
        רולינג של שנים עשרה. כאשר אתה מבקש את זה, המידע שנסופק עשוי להיות מוגבל
        למידע האישי שאספנו עליך ב-12 החודשים הקודמים.
        <br />
        למחוק את המידע האישי שלך. אם אתה מבקש את זה, נמחק את המידע האישי שיש לנו
        עליך מהתיקים שלנו מתאריך הבקשה שלך ונורה לכל ספקי השירותים לעשות אותו
        הדבר. במקרים מסוימים, המחיקה יכולה להתבצע על ידי דישון המידע. אם תבחר
        למחוק את המידע האישי שלך, עשוי להיות שלא תוכל להשתמש בפונקציות מסוימות
        שדורשות את המידע האישי שלך כדי לפעול.
        <br />
        להפסיק למכור את המידע האישי שלך. אנו לא מוכרים או משכירים את המידע האישי
        שלך לצדדים שלישיים לשום מטרה. אתה הבעלים היחיד של הנתונים האישיים שלך
        ויכול לבקש חשיפה או מחיקה בכל עת.
        <br />
        שים לב, אם תבקש מאיתנו למחוק או להפסיק למכור את הנתונים שלך, זה עשוי
        להשפיע על חוויית השימוש שלך איתנו, ואתה עשוי לא להיות מסוגל להשתתף
        בתכניות או בשירותי חברים מסוימים שדורשים את שימושם במידע האישי שלך כדי
        לפעול. אך בכל מקרה, לא ננהל הפלגה נגדך בשום צורה.
        <br />
        כדי להפעיל את זכויות הגנת הנתונים שלך בקליפורניה כפי שתוארו לעיל, נא
        לשלוח את בקשותיך באחד מהדרכים הבאות:
        <br />
        באמצעות אימייל: info@thunder.co.il
        <br />
        על ידי מספר טלפון: 0547938785
        <br />
        זכויות הגנת הנתונים שלך, שתוארו לעיל, מוסמכות על ידי ה-CCPA, קיצור של
        חוק הפרטיות של צרפת לצרכן. כדי לקבל מידע נוסף, יש לבקר באתר הרשמי של
        מידע החקיקה של קליפורניה. ה-CCPA נכנס לתוקפו ב-01/01/2020.
        <br />
        ספקי השירות
        <br />
        אנו עשויים לפעול באמצעות חברות ואנשים צדדים כדי לסייע בפעילות שלנו
        (“ספקי השירות”), לספק שירות במקומנו, לבצע שירותים קשורים לשירות או לעזור
        לנו בניתוח כיצד השירות שלנו משמש.
        <br />
        לספקים הצדדים האלה יש גישה לנתוני האישיים שלך רק כדי לבצע את המשימות
        האלה במקומנו והם מחוייבים לא לחשוף או להשתמש בהם לכל מטרה אחרת.
        <br />
        <br />
        ניתוח
        <br />
        יתכן שנשתמש בספקי שירות צד שלישי כדי לפקח ולנתח את השימוש בשירות שלנו.
        <br />
        Google Analytics
        <br />
        Google Analytics הוא שירות ניתוח רשת שמציעה גוגל ומעקב אחר תנועת האתרים
        ודיווחיה. גוגל משתמשת בנתונים הנאספים כדי למעקב ולניטור בשימוש בשירות
        שלנו ומשתף את הנתונים הללו עם שירותים אחרים של גוגל. גוגל עשוי להשתמש
        בנתונים שנאספים להקשת והתאמת המודעות ברשת הפרסומת שלה.
        <br />
        למידע נוסף על מדיניות הפרטיות של גוגל, ניתן לבקר בדף תנאי הפרטיות של
        גוגל: https://policies.google.com/privacy?hl=en
        <br />
        אנו ממליצים גם לבדוק את מדיניות הגוגל להגנת הנתונים שלך:
        https://support.google.com/analytics/answer/6004245.
        <br />
        Mixpanel
        <br />
        Mixpanel מסופק על ידי Mixpanel Inc.
        <br />
        ניתן למנוע מ-Mixpanel להשתמש במידע שלך למטרות ניתוח על ידי ביצוע הפסקה.
        כדי לבצע הפסקה משירות Mixpanel, יש לבקר בדף זה:
        https://mixpanel.com/optout/
        <br />
        לקבלת מידע נוסף על סוגי המידע ש-Mixpanel אוסף, יש לבקר בדף תנאי השימוש
        של Mixpanel: https://mixpanel.com/terms/
        <br />
        LogRocket
        <br />
        LogRocket מסופק על ידי LogRocket Inc.
        <br />
        למידע נוסף על מדיניות הפרטיות של LogRocket, יש לבקר בדף פרטיות
        ה-LogRocket: https://docs.logrocket.com/docs/privacy
        <br />
        לקבלת מידע נוסף על סוגי המידע ש-LogRocket אוסף, יש לבקר בדף תנאי השימוש
        של LogRocket: https://logrocket.com/terms-and-conditions
        <br />
        Yandex.Metrica
        <br />
        Yandex.Metrica מסופק על ידי Yandex LLC. ניתן למנוע מ-Yandex.Metrica
        להשתמש במידע שלך למטרות ניתוח על ידי ביצוע הפסקה. כדי לבצע הפסקה משירות
        Yandex.Metrica, יש לבקר בדף זה:
        https://yandex.ru/support/metrica/general/opt-out.html?lang=en
        <br />
        לקבלת מידע נוסף על סוגי המידע ש-Yandex.Metrica אוסף, יש לבקר בדף תנאי
        השימוש של Yandex.Metrica: https://yandex.com/legal/metrica_termsofuse/
        <br />
        <br />
        כלי CI/CD
        <br />
        יתכן שנשתמש בספקי שירות צד שלישי כדי לאוטומציה של תהליך הפיתוח של השירות
        שלנו.
        <br />
        GitHub
        <br />
        GitHub מסופק על ידי GitHub, Inc.
        <br />
        GitHub הוא פלטפורמת פיתוח לאחסון וסקירת קוד, ניהול פרויקטים, ובניית
        תוכנה.
        <br />
        לקבלת מידע נוסף על הנתונים ש-GitHub אוסף למהם מטרה ואיך מתבצעת ההגנה על
        הנתונים, יש לבקר בדף מדיניות הפרטיות של GitHub:
        https://help.github.com/en/articles/github-privacy-statement.
        <br />
        <br />
        שיווק מחדש התנהגותי
        <br />
        BRASK INC משתמשת בשירותי שיווק מחדש כדי לפרסם מודעות באתרי צד שלישי אליך
        לאחר שביקרת בשירות שלנו. אנו וספקי השירות שלנו משתמשים בעוגיות כדי
        ליידע, לייעל ולשרת מודעות בהתאם לביקורים הקודמים שלך בשירות שלנו.
        <br />
        Google Ads (AdWords)
        <br />
        שירות השיווק מחדש של Google Ads (AdWords) מסופק על ידי Google Inc.
        <br />
        ניתן לבחור שלא להשתמש ב-Google Analytics עבור פרסומות ברשת תצוגת Google
        ולהתאים אישית את המודעות ברשת תצוגת Google על ידי ביקור בדף ההגדרות של
        Google Ads: http://www.google.com/settings/ads
        <br />
        Google ממליצה גם להתקין את התוסף לדפדפן Google Analytics Opt-out Browser
        Add-on – https://tools.google.com/dlpage/gaoptout – לדפדפן האינטרנט שלך.
        תוסף זה מאפשר למבקרים למנוע את איסוף הנתונים שלהם ואת השימוש בהם על ידי
        Google Analytics.
        <br />
        למידע נוסף אודות מדיניות הפרטיות של Google, יש לבקר בדף תנאי הפרטיות של
        Google: https://policies.google.com/privacy?hl=en
        <br />
        Facebook
        <br />
        שירות השיווק מחדש של Facebook מסופק על ידי Facebook Inc.
        <br />
        ניתן למצוא מידע נוסף אודות פרסום בהתבסס על עניין מצד Facebook באתר הבא:
        https://www.facebook.com/help/164968693837950
        <br />
        כדי לבטל את המודעות בהתבסס על עניין מצד Facebook, יש לעקוב אחר ההוראות
        הבאות של Facebook: https://www.facebook.com/help/568137493302217
        <br />
        Facebook נאמנה לעקרונות התנהגותיים של אינטרנט לפרסום רגולציוני שהוקם על
        ידי האיחוד לפרסום באינטרנט. ניתן גם לבחור באפשרות לא להשתמש ב-Facebook
        ובחברות המשתתפות אחרות דרך הקישורים הבאים: Digital Advertising Alliance
        in the USA http://www.aboutads.info/choices/, Digital Advertising
        Alliance of Canada in Canada http://youradchoices.ca/ או האיחוד האירופי
        לפרסום דיגיטלי אינטראקטיבי באירופה http://www.youronlinechoices.eu/, או
        באמצעות הגדרות מכשיר הנייד שלך.
        <br />
        למידע נוסף אודות מדיניות הפרטיות של Facebook, יש לבקר במדיניות הנתונים
        של Facebook: https://www.facebook.com/privacy/explanation
        <br />
        Spotify
        <br />
        שירות השיווק מחדש של מודעות Spotify ניתן על ידי Spotify AB. ניתן לנהל את
        העדפות המודעות האישיות ישירות באפליקציית Spotify: עבור לדף הבית &gt;
        הגדרות &gt; העדפות מודעות או בקר בדף הבא:
        https://www.spotify.com/us/account/privacy/
        <br />
        Spotify מציעה גם תכונת "פעילות פרטית" שניתן להפעיל מאותו תפריט ההגדרות
        באפליקציה. זה ימנע מ-Spotify לאסוף מידע אודות הקשבתך למוסיקה למשך תקופת
        הפעילות.
        <br />
        למידע נוסף אודות מדיניות הפרטיות של Spotify, יש לבקר בדף מדיניות הפרטיות
        של Spotify: https://www.spotify.com/us/legal/privacy-policy/
        <br />
        <br />
        תשלומים
        <br />
        אנו עשויים לספק מוצרים ו/או שירותים בתשלום במסגרת השירות שלנו. במקרה
        כזה, אנו משתמשים בשירותים של צדדים שלישיים לעיבוד התשלומים (לדוגמה,
        עובדי עיבוד תשלומים).
        <br />
        לא נאחסן ולא אוספים מידע הזהוי אישית של כרטיס התשלום שלך. המידע הזה
        מסופק ישירות לעובדי עיבוד התשלומים של צדדים שלישיים שלנו, והשימוש שלהם
        במידע האישי שלך כפוף למדיניות הפרטיות שלהם. עובדי עיבוד התשלומים הללו
        נאמנים לתקנות המוצגות על ידי PCI-DSS כפי שננהלות על ידי מועצת תקנות
        אבטחת התשלומים PCI, שהיא מאמצת משותפת של מותגים כמו Visa, Mastercard,
        American Express ו-Discover. דרישות ה-PCI-DSS עוזרות להבטיח את הטיפול
        המאובטח במידע התשלומים.
        <br />
        עובדי עיבוד התשלומים שאנו עובדים איתם הם:
        <br />
        Cardcom:
        <br />
        המדיניות הפרטיות שלהם ניתנת לצפייה ב:
        https://www.cardcom.solutions/מדיניות-פרטיות
        <br />
        קישורים לאתרים אחרים
        <br />
        השירות שלנו עשוי להכיל קישורים לאתרים אחרים שאינם מנוהלים על ידינו. אם
        תלחץ על קישור של צד שלישי, תועבר לאתר של הצד השלישי ההוא. אנו ממליצים
        בחום לך לסקור את מדיניות הפרטיות של כל אתר שאתה מבקר בו.
        <br />
        אנו איננו שולטים על ואין לנו שום אחריות לתוכן, למדיניות הפרטיות או
        לפרקטיקות של מקומות אתרים או שירותים של צדדים שלישיים.
        <br />
        פרטיות ילדים
        <br />
        השירותים שלנו אינם מיועדים לשימוש על ידי ילדים מתחת לגיל 13 (“ילדים”).
        <br />
        אנו לא מקבלים במודעה ולא אוספים מידע זהוי אישית מילדים מתחת לגיל 13. אם
        יש לך מודעה כי ילד סיפק לנו מידע אישי, אנא צור עמנו קשר. אם נכנס לידינו
        הודעה שלא אמותה בנוגע להסכמת הורים, אנו מבצעים צעדים להסרתו משרתינו.
        <br />
        שינויים במדיניות הפרטיות הזאת
        <br />
        אנו עשויים לעדכן מדיניית הפרטיות שלנו מעת לעת. אנו נודיע לך על כל שינוי
        על ידי פרסום מדיניית הפרטיות החדשה על דף זה.
        <br />
        אנו נודיע לך באמצעות דוא"ל ו/או הודעה בולטת בשירות שלנו, לפני שינוי זה
        יהיה פעיל ונעדכן את "תאריך התחילת התוקף" בראש מדיניית הפרטיות הזאת.
        <br />
        מומלץ לך לבדוק מדיניית פרטיות זו מדי פעם לכל שינויים. שינויים במדיניית
        הפרטיות הזאת יהיו בתוקף מהרגע שהם מתווספים לדף זה.
        <br />
        צור קשר איתנו
        <br />
        אם יש לך שאלות בנוגע למדיניית הפרטיות הזאת, אנא צור עמנו קשר:
        <br />
        בדוא"ל: info@thunder.co.il.
        <br />
        <br />
      </p>
    </>
  );
};

export default PrivacyPolicy;
