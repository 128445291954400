import React from "react";
import { Modal, Box, Button, Typography, Grid } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import styled from "styled-components";

// Define types for props
interface SocialShareModalProps {
  isOpen: boolean;
  handleClose: () => void;
  videoUrl: string;
}
const ModalContainer = styled(Box)`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;


const ShareButton = styled(Button)`
  &.MuiButton-root {
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;

    align-items: center;
    justify-content: center;
  }
`;
const StyledFacebookIcon = styled(FacebookIcon)`
  color: #1877f2;
  width: 100;
`;
const iconStyles = {
  fontSize: "48px", // Increase the icon size
  marginRight: "8px", // Add margin to separate the icon from the button text
};
const StyledInstagramIcon = styled(InstagramIcon)`
  /* Add styles for the InstagramIcon here */
  color: #c32aa3; /* Example color */
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  color: #1da1f2; /* Example color */
`;
const SocialShareModal: React.FC<SocialShareModalProps> = ({
  isOpen,
  handleClose,
  videoUrl,
}) => {
  // Function to handle Facebook button click
  const handleFacebookShare = () => {
    // Implement your Facebook sharing logic here

    
    const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      videoUrl
    )}&appId=373632785057076`;

    // Open the Facebook share dialog in a new window.
    window.open(facebookShareURL, "_blank");
  };

  // Function to handle Instagram button click
  const handleInstagramShare = () => {
 
  };

  // Function to handle Twitter button click
  const handleTwitterShare = () => {
    // Implement your Twitter sharing logic here
  };
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalContainer>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <ShareButton color="primary" onClick={handleFacebookShare}>
              <FacebookIcon style={iconStyles} />
            </ShareButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <ShareButton color="secondary" onClick={handleInstagramShare}>
              <InstagramIcon style={iconStyles} />
            </ShareButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <ShareButton color="info" onClick={handleTwitterShare}>
              <TwitterIcon style={iconStyles} />
            </ShareButton>
          </Grid>
        </Grid>
      </ModalContainer>
    </Modal>
  );
};

export default SocialShareModal;
