import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Avatar, Grid, Paper, Tab, Tabs } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import SecurityIcon from "@mui/icons-material/Security";
import styled from "styled-components";
import UserEditPortal from "./UserEditPortal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import axios from "axios";
import { AxiosResponse } from "axios";
import { Dialog, DialogContent } from "@mui/material";
import IframePage from "../paymentCalls/IframePage";
const StyledContainer = styled(Container)`
  width: 100%;
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
  width: 100%;
  height: 90vh;
`;
const StyledTypography = styled(Typography)`
  color: #4a90e2; // Change to your preferred color
  font-size: 20px; // Adjust font size
  font-weight: 500; // Adjust font weight
  text-align: center; // Center align text
  margin: 10px 0; // Add margin for spacing
  padding-bottom: 5px; // Padding below the text
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Box shadow for a subtle depth effect
  transition: all 0.3s ease; // Smooth transition for hover effects

  &:hover {
    background-color: #e0e0e0; // Change background color on hover
    color: #3a78b5; // Change text color on hover
  }
`;
const StyledSelect = styled.select`
  padding: 0.8rem;
  margin-right: 1.5rem; // Increased margin

  border: 2px solid #4a90e2;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: none;
    border-color: #3a78b5;
  }
`;
const PlanDetails = styled.div`
  display: flex;
  margin-top: 2rem; // Increased margin for spacing
  padding: 1rem;
  // ... other styles
`;

const StyledButton = styled(Button)`
  && {
    padding: 0.6rem 1.2rem;
    margin-right: 1.5rem; // Added margin for spacing

    background-color: #4a90e2;
    color: white;

    &:hover {
      background-color: #3a78b5;
    }
  }
`;
const StyledAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
`;
const TabContent = styled.div``;
const UserEditProtal = () => {
  const planPrices = {
    basic: "₪0",
    silver: "₪159",
    gold: "₪229",
  };
  const [activeTab, setActiveTab] = useState<number>(0);
  const [plan, setPlane] = useState<any>("");
  const [userData, setUserData] = useState<any>(null);
  const [idProfile, setIdProfile] = useState<any>("");
  const [userId, setUserId] = useState<any>("");
  const [open, setOpen] = useState<boolean>(false);
  const [dialogUrl, setDialogUrl] = useState<string>("");
  const [selectedPlan, setSelectedPlan] = useState(""); // Add a state for the selected plan
  const [price, setPrice] = useState<number>(0);
  const [fullName, setFullName] = useState<string>("");
  const [LowProfileDealGuid, setLowProfileDealGuid] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [lowProfileCode, setLowProfileCode] = useState<string>("");

  console.log(plan);
  useEffect(() => {
    const localId = localStorage.getItem("id");

    setIdProfile(localId);
  }, []);
  const handleClose = () => {
    setOpen(false);
    setSelectedPlan(""); // Clear the selected plan when the dialog is closed
    checkPaymentStatus(lowProfileCode);
    setPrice(0);
  };
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };
  console.log(userData);

  const user = useSelector((state: RootState) => state.auth.user); // Replace "auth" with your Redux slice name

  useEffect(() => {
    if (userData?.selectedProgram === "basic") {
      setPlane("בסיסי");
    }
    if (userData?.selectedProgram === "silver") {
      setPlane("סילבר");
    }
    if (userData?.selectedProgram === "gold") {
      setPlane("גולד");
    }
  }, [userData?.selectedProgram]);

  const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlan = event.target.value;
    let newPrice = 0;
    // Set the selected plan and price based on the user's selection
    if (selectedPlan === "basic") {
      setSelectedPlan("basic");
      setPrice(0);
    } else if (selectedPlan === "silver") {
      setSelectedPlan("silver");
      setPrice(159);
    } else if (selectedPlan === "gold") {
      setSelectedPlan("gold");
      setPrice(229);
    }
    setSelectedPlan(selectedPlan); // Update the selected plan

    // Set the new plan in state (optional, if needed elsewhere)
    setNewPlan(selectedPlan);
  };

  const [newPlan, setNewPlan] = useState(plan); // state for the new plan
  const submitPlanChange = () => {
    // Call the sendCardcomPaymentRequest function with the selectedPlan and price
    sendCardcomPaymentRequest(selectedPlan, price);
  };
  const fetchUserData = async () => {
    try {
      const baseURL = process.env.REACT_APP_API_URL;

      const response = await axios.get(`${baseURL}/user/getuser/${user.email}`); // Replace "your-email" with the actual email to fetch
      setUserData(response.data.user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const updatePlanOnBackend = async (plan: any) => {
    const baseURL = process.env.REACT_APP_API_URL;
    try {
      const response = await axios.put(`${baseURL}/user/updateplan/${user}`, {
        newPlan: plan,
      });
      if (response.status === 200) {
        console.log("Plan updated successfully");
        // Optionally, update the Redux store or local state here
      } else {
        console.error("Failed to update the plan");
      }
    } catch (error) {
      console.error("Error updating the plan:", error);
    }
  };
  useEffect(() => {
    // Fetch user data when the component mounts
    fetchUserData();
  }, [idProfile]);

  const checkPaymentStatus = async (lowProfileCode: string) => {
    const low = lowProfileCode;
    console.log("low", low);
    try {
      const baseURL = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${baseURL}/pay/get-low-profile-indicator`,
        {
          params: {
            lowProfileCode: low,
          },
        }
      );

      if (response.status === 200) {
        const paymentStatus = response?.data;
        console.log("paymentStatus", paymentStatus);

        // Attempt to find ResponseCode in the HTML content
        const responseCodeMatch = /ResponseCode=(\d+)/.exec(paymentStatus);

        if (responseCodeMatch) {
          const responseCode = parseInt(responseCodeMatch[1], 10);
          console.log("responseCode", responseCode);

          if (responseCode === 0) {
            // Payment was successful
            console.log("Payment was successful");
            directDebit(selectedPlan, price, lowProfileCode);
          } else {
            // Payment was not successful
            console.log("Payment failed");
          }
        } else {
          // ResponseCode not found in HTML content
          console.log("ResponseCode not found in the response.");
        }
      }
    } catch (error) {
      console.error("Failed to fetch payment status:", error);
    }
  };

  const sendCardcomPaymentRequest = async (selectedPlan: any, price: any) => {
    try {
      const baseURL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${baseURL}/pay/payment-request`, {
        selectedPlan,
        price,
        userEmail,
        fullName,
      });
      const { data } = response;
      console.log(data);

      if (data) {
        const responseString = data;
        const responseArray = responseString.split("&");
        let url = "";

        for (const pair of responseArray) {
          const [key, value] = pair.split("=");

          if (key === "url") {
            // Decode the URL to get the actual URL
            url = decodeURIComponent(value);
            break; // Exit the loop once 'url' is found
          }
        }

        console.log(url);
        const parsedUrl = new URL(url);

        const lowProfileCode = parsedUrl.searchParams.get("LowProfileCode");
        console.log(lowProfileCode);
        if (lowProfileCode !== null) {
          localStorage.setItem("lowProfileCode", lowProfileCode);
          setLowProfileCode(lowProfileCode);
        }
        setDialogUrl(url); // Set the URL to open in the dialog
        setOpen(true); // Open the dialog
      }
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };
  const handleProgramSelect = async (program: string) => {
    try {
      const baseURL = process.env.REACT_APP_API_URL;

      const response: AxiosResponse = await axios.put(
        `${baseURL}/user/selectprogram/${userId}`,
        {
          selectedProgram: program,
        }
      );

      if (response.status === 200) {
        console.log("Program selection successful");
        localStorage.setItem("program", program);
        // Navigate to the appropriate route, e.g., "/UserPortal"
      } else {
        console.error("Program selection failed");
        // Handle the failure gracefully, show an error message, etc.
      }
    } catch (error: any) {
      console.error("Program selection failed:", error);
      // Handle the failure gracefully, show an error message, etc.
    }
  };
  const directDebit = async (
    selectedPlan: any,
    price: number,
    LowProfileDealGuid: string
  ) => {
    const baseURL = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.get(`${baseURL}/pay/cardcom-request`, {
        params: {
          selectedPlan: selectedPlan,
          price: price,
          CardOwnerName: fullName,
          LowProfileDealGuid: LowProfileDealGuid,
        },
      });

      if (selectedPlan) {
        handleProgramSelect(selectedPlan);
      }

      console.log("res.data", response.data);
    } catch (error) {
      console.error("HTTP Request Error:", error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#f6fcff",
      }}
    >
      <div
        style={{
          backgroundColor: "#f6fcff",
          paddingTop: "40px",
        }}
      >
        <StyledContainer maxWidth="md">
          <StyledPaper elevation={3} sx={{}}>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h4" gutterBottom>
                    הגדרות משתמש
                  </Typography>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab label="פרופיל" icon={<AccountCircleIcon />} />
                    <Tab label="התוכנית שלי" icon={<SettingsIcon />} />
                    <Tab label="תשלום" icon={<SecurityIcon />} />
                  </Tabs>
                  {activeTab === 0 && (
                    <TabContent>
                      {/* Profile Tab Content */}

                      <UserEditPortal />
                    </TabContent>
                  )}
                  {activeTab === 1 && (
                    <TabContent
                      style={{
                        paddingTop: "40px",
                      }}
                    >
                      {/* Settings Tab Content */}
                      <StyledTypography variant="subtitle1">
                        <div>תוכנית המנוי שלך: {plan}</div>
                      </StyledTypography>
                      <PlanDetails>
                        <StyledSelect
                          value={newPlan} // Set the value to the currently selected plan
                          onChange={handlePlanChange}
                        >
                          <option value="basic">בסיסי - ₪0</option>
                          <option value="silver">סילבר - ₪159</option>
                          <option value="gold">גולד - ₪229</option>
                        </StyledSelect>

                        <StyledButton onClick={submitPlanChange}>
                          שנה תוכנית
                        </StyledButton>
                      </PlanDetails>
                    </TabContent>
                  )}
                  {activeTab === 2 && (
                    <TabContent>
                      {/* Security Tab Content */}
                      <Typography variant="subtitle1">
                        Ensure the security of your account.
                      </Typography>
                    </TabContent>
                  )}
                </Grid>
              </Grid>
            </Box>
          </StyledPaper>
        </StyledContainer>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            {dialogUrl && <IframePage dialogUrl={dialogUrl} />}
          </DialogContent>
          {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
        </DialogActions> */}
        </Dialog>
      </div>
    </div>
  );
};

export default UserEditProtal;
