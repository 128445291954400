import React from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  Grid,
} from "@mui/material";
import {
  SigninSection,
  SinginLeft,
  SinginRight,
  HeaderContainer,
  SigninContainerButton,
  Button55,
} from "./SignUp.style";
import { useSelector, useDispatch } from "react-redux"; // Add useSelector and useDispatch
import bcrypt from "bcryptjs";
import CircularProgress from "@mui/material/CircularProgress";

import PrivacyPolicy from "../../components/privacyPolicy/PrivacyPolicy";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import axios from "axios";
import { setUserData } from "../../redux/authSlice";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import Dialog from "@mui/material/Dialog";

interface SignInFormInputs {
  email: string;
  password: string;
  confirmPassword: string;
  fullName: string;
  remember: boolean;
  agreeToTerms: boolean;
}

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch(); // Initialize the dispatch function
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [registrationSuccess, setRegistrationSuccess] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false); // New loading state

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues, // Access form values using getValues
  } = useForm<SignInFormInputs>();
  const password = getValues("password");
  const handleOpenPrivacyPolicy = () => {
    setOpenPrivacyPolicy(true);
  };

  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };
  const onSubmit = async (data: SignInFormInputs) => {
    setIsLoading(true); // Start loading
    try {
      const hashedPassword = await bcrypt.hash(data.password, 10); // You can adjust the number of salt rounds
      const userData = {
        ...data,
        password: hashedPassword,
        confirmPassword: hashedPassword,
      };
      const response = await axios.post(`${apiUrl}/user/register/`, data);

      if (response.status === 200) {
        console.log("Registration successful");
        setRegistrationSuccess(true);
        if (registrationSuccess) {
          dispatch(setUserData(userData)); // You can update this with the actual user data
          setTimeout(() => {
            navigate("/signin");
          }, 3000);
        }
      } else {
        console.error("Registration failed");
      }
    } catch (error: any) {
      setIsLoading(false); // Stop loading
      if (error.response && error.response.status === 400) {
        alert("המייל כבר קיים במערכת");
      }
      if (error.response && error.response.status === 500) {
        alert("שגיאה בהרשמה");
      }
      if (error.response && error.response.status === 404) {
        alert("שגיאה בהרשמה");
      }
      if (error.response && error.response.status === 401) {
        alert("שגיאה בהרשמה");
      }
      if (error.response && error.response.status === 403) {
        alert("שגיאה בהרשמה");
      }
      if (error.response && error.response.status === 409) {
        alert("שגיאה בהרשמה");
      }

      console.error("Registration failed:", error);
    }
  };

  const theme = createTheme({
    direction: "rtl",
  });

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });
  console.log(apiUrl);

  return (
    <SigninSection container>
      <SinginRight item xs={12} md={5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography variant="h4" gutterBottom>
            <HeaderContainer>ברוכים הבאים</HeaderContainer>
          </Typography>
          {registrationSuccess ? (
            <div>
              <Typography variant="h4">ההרשמה הצליחה </Typography>
              <Typography style={{ color: "#333", marginTop: "1rem" }}>
                הודעת אימות נשלחה לכתובת הדוא"ל הרשומה שלך. אנא בדוק את תיבת
                הדואר הנכנס שלך ועקוב אחר ההוראות לאימות הדוא"ל שלך.
              </Typography>
            </div>
          ) : (
            <>
              <CacheProvider value={cacheRtl}>
                <ThemeProvider theme={theme}>
                  <div dir="rtl">
                    <Controller
                      name="fullName"
                      control={control}
                      defaultValue=""
                      rules={{ required: "שדה חובה" }}
                      render={({ field }) => (
                        <TextField
                          label="שם מלא"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          {...field}
                          error={!!errors.fullName}
                          helperText={errors.fullName?.message}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "white", // This will change the border color to black on hover
                              },
                            },

                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },
                            "& .MuiInputLabel-root": {
                              color: "white",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },

                            "& .MuiOutlinedInput-input": {
                              color: "white",
                            },
                            "& .MuiFormLabel-root": {
                              color: "white",
                            },

                            "& .MuiFormHelperText-root": {
                              color: "white",
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ required: "דרושה איימל" }}
                      render={({ field }) => (
                        <TextField
                          label="דואר אלקטרוני"
                          type="email"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          {...field}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "white", // This will change the border color to black on hover
                              },
                            },

                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },
                            "& .MuiInputLabel-root": {
                              color: "white",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },

                            "& .MuiOutlinedInput-input": {
                              color: "white",
                            },
                            "& .MuiFormLabel-root": {
                              color: "white",
                            },

                            "& .MuiFormHelperText-root": {
                              color: "white",
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="password"
                      control={control}
                      defaultValue=""
                      rules={{ required: "דרושה סיסמא" }}
                      render={({ field }) => (
                        <TextField
                          label="סיסמא"
                          type="password"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          {...field}
                          error={!!errors.password}
                          helperText={errors.password?.message}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "white", // This will change the border color to black on hover
                              },
                            },

                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },
                            "& .MuiInputLabel-root": {
                              color: "white",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },

                            "& .MuiOutlinedInput-input": {
                              color: "white",
                            },
                            "& .MuiFormLabel-root": {
                              color: "white",
                            },

                            "& .MuiFormHelperText-root": {
                              color: "white",
                            },
                          }}
                        />
                      )}
                    />
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          label="אישור סיסמא"
                          type="password"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          {...field}
                          error={!!errors.confirmPassword}
                          helperText={
                            errors.confirmPassword
                              ? "הסיסמאות אינן תואמות"
                              : undefined // Clear the error message when there's no error
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "white", // This will change the border color to black on hover
                              },
                            },

                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },
                            "& .MuiInputLabel-root": {
                              color: "white",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "white",
                              },

                            "& .MuiOutlinedInput-input": {
                              color: "white",
                            },
                            "& .MuiFormLabel-root": {
                              color: "white",
                            },

                            "& .MuiFormHelperText-root": {
                              color: "white",
                            },
                          }}
                        />
                      )}
                    />

                    <FormControlLabel
                      control={
                        <Controller
                          name="agreeToTerms"
                          control={control}
                          defaultValue={false}
                          rules={{
                            required:
                              "יש להסכים לתנאים ולהגבלות ולמדיניות הפרטיות",
                          }}
                          render={({ field }) => <Checkbox {...field} />}
                        />
                      }
                      label={
                        <Typography sx={{ color: "white" }}>
                          בלחיצה על "הירשם" אני מסכים ל
                          <Link
                            onClick={handleOpenPrivacyPolicy}
                            underline="hover"
                          >
                            {" "}
                            תנאים ולהגבלות
                          </Link>
                          {", "}
                          ול
                          <Link
                            onClick={handleOpenPrivacyPolicy}
                            underline="hover"
                          >
                            מדיניות הפרטיות
                          </Link>
                          .
                        </Typography>
                      }
                    />
                  </div>
                </ThemeProvider>
              </CacheProvider>
              {errors.agreeToTerms && (
                <Typography color="error">
                  {errors.agreeToTerms.message}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                disabled={isLoading} // Disable button when loading
                fullWidth
                style={{
                  marginTop: "1rem",
                  backgroundColor: "#760BE0",
                  color: "white",
                }}
              >
                {isLoading ? <CircularProgress size={24} /> : "הירשם"}
              </Button>
              <Typography
                style={{
                  marginTop: "1rem",
                  textAlign: "center",
                  color: "white",
                }}
              >
                יש לך משתמש?{" "}
                <Link href="/signin" underline="hover">
                  לחץ כאן
                </Link>
              </Typography>
            </>
          )}
        </form>
      </SinginRight>

      <SinginLeft item xs={12} md={7}></SinginLeft>
      <Dialog
        open={openPrivacyPolicy}
        onClose={handleClosePrivacyPolicy}
        maxWidth="sm"
        fullWidth
      >
        <PrivacyPolicy />
      </Dialog>
    </SigninSection>
  );
};

export default SignUp;
