import React, { useState } from "react";
import axios from "axios";
import { TextField, Button, Alert, CircularProgress } from "@mui/material";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setMessage("");
    setError("");

    // Start loading
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/forgot-password`,
        { email }
      );
      setMessage("בקשת שחזור סיסמה נשלחה לדואר האלקטרוני שלך.");
    } catch (error) {
      setError("שגיאה בשליחת בקשת שחזור סיסמה");
    } finally {
      // Stop loading
      setLoading(false);
    }
  };

  return (
    <div>
      <h3>שחזור סיסמא</h3>
      <form onSubmit={handleSubmit}>
        <TextField
          label="דואר אלקטרוני"
          type="email"
          fullWidth
          margin="normal"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />

        <Button
          type="submit"
          variant="contained"
          style={{
            marginTop: "1rem",
            width: "100%",
            backgroundColor: "#760BE0",
            color: "white",
          }}
        >
          שחזור סיסמא
        </Button>
      </form>
      {error && <Alert severity="error">{error}</Alert>}
      {message && <Alert severity="success">{message}</Alert>}
      {loading && <CircularProgress />} {/* Display loading indicator */}
    </div>
  );
};

export default ForgotPassword;
