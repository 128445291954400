import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TextField, Button, Alert, Container } from "@mui/material";

interface PasswordResetProps {}

const PasswordReset: React.FC<PasswordResetProps> = () => {
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setMessage("");
    setError("");

    if (password !== confirmPassword) {
      setError("אין התאמה בין הסיסמאות");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/reset-password/${token}`,
        { password }
      );
      window.location.href = "https://thunder.co.il/";

      setMessage(response.data.message);
    } catch (error) {
      setError("Error resetting password");
    }
  };

  return (
    <Container maxWidth="sm">
      <div>
        <h3>נא להזין את הסיסמה החדשה שלך ולאשר אותה בשדה השני.</h3>
        <form onSubmit={handleSubmit}>
          <TextField
            label="New Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextField
            label="Confirm Password"
            type="password"
            fullWidth
            margin="normal"
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              marginTop: "1rem",
              width: "100%",
            }}
          >
            שחזור סיסמא
          </Button>
        </form>
        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}
      </div>
    </Container>
  );
};

export default PasswordReset;
