import React from "react";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledMainTitleQuestion,
  StyledSubTitle,
  StyledAccordionSummary,
  StyledAccordionSummaryText,
  StyledAccordionDetailsAnswer,
  CricleBackground,
  StyledContainerQuestion,
  StyledButton,
} from "./Home.style";
import { Helmet } from "react-helmet";

interface FAQItem {
  question: string;
  answer: string;
}
const faqItems: FAQItem[] = [
  {
    question: "מהו דיבוב AI לווידאו?",
    answer:
      "דיבוב AI לווידאו הוא תהליך בו מערכת מתקדמת של למידת מכונה מייצרת קול סינתטי שמדמה את הדיבור האנושי. היא מאפשרת להחליף את הקול המקורי בווידאו בקול חדש, מבלי לשנות את הווידאו עצמו. זה משמש לצורכים שונים כמו דיבוב לשפות אחרות, יצירת תוכן מותאם אישית ועוד.",
  },
  {
    question: "האם דיבוב AI יכול להיות מותאם לקולות ספציפיים?",
    answer:
      "כן, רוב מערכות הדיבוב של AI יכולות להיות מותאמות לקולות ספציפיים. הן משתמשות בנתונים קוליים מוקלטים כדי ליצור מודל קולי שמדמה קול אנושי מסוים. זה מאפשר יצירת דיבוב שנשמע טבעי ומתאים לצורך הספציפי.",
  },

  {
    question: " האם דיבוב AI מתאים לכל סוגי הווידאו?",
    answer:
      "תשובה: דיבוב AI יכול להיות מתאים לרוב סוגי הווידאו, אך ישנם גבולות. למשל, וידאו עם דיבור מהיר מאוד או עם רעש רקע חזק עשוי להקשות על יכולת המערכת לייצר דיבוב איכותי. בנוסף, ייתכן שצריך לעבור תיקונים והתאמות ידניות במקרים מסוימים.",
  },
  {
    question: " כמה זמן לוקח למערכת AI לדבב וידאו?",
    answer:
      "תשובה: זמן הדיבוב תלוי באורך הווידאו ובמורכבות הטקסט. בדרך כלל, מערכת AI יכולה לעבד ולדבב וידאו בזמן קצר יחסית, לעיתים תוך דקות. אך, ייתכנו מקרים בהם נדרשת עבודה ידנית נוספת שתגרום לתהליך להיות ארוך יותר.",
  },
  {
    question: "שאלה 5: האם קיימות הגבלות אתיות בשימוש בדיבוב AI?",
    answer:
      "תשובה: כן, קיימות הגבלות אתיות בשימוש בדיבוב AI, במיוחד בנושאים של זכויות יוצרים ופרטיות. חשוב להיות מודעים לחוקים ולהנחיות האתיות הקיימות כדי למנוע שימוש לרעה בטכנולוגיה זו, כמו יצירת תוכן מזויף או שימוש בקולות של אנשים ללא הסכמתם.",
  },
];

const QuestionsAndAnswers = () => {
  return (
    <Container maxWidth="xl">
      <Helmet>
        <meta
          name="description"
          content="מצא תשובות לשאלות נפוצות על דיבוב AI לווידאו ושירותי THUNDER. גלו כיצד המערכת עובדת ועוד."
        />
        <meta
          name="keywords"
          content="דיבוב, AI, וידאו, שאלות ותשובות, טכנולוגיה"
        />
        <meta property="og:title" content="שאלות ותשובות" />
        <meta
          property="og:description"
          content="מצא תשובות לשאלות נפוצות על דיבוב AI לווידאו ושירותי THUNDER. גלו כיצד המערכת עובדת ועוד."
        />
        <meta property="og:image" content="URL_TO_YOUR_IMAGE" />
        <meta property="og:url" content="URL_TO_YOUR_PAGE" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="שאלות ותשובות" />
        <meta
          name="twitter:description"
          content="מצא תשובות לשאלות נפוצות על דיבוב AI לווידאו ושירותי THUNDER. גלו כיצד המערכת עובדת ועוד."
        />
        <meta name="twitter:image" content="URL_TO_YOUR_IMAGE" />
      </Helmet>
      <Grid container>
        <Grid item lg={5}>
          <StyledContainerQuestion>
            <div className="title-style-one">
              <StyledMainTitleQuestion>
                יש שאלות?
                <br />
                <span>תמצא</span> כאן.
              </StyledMainTitleQuestion>
            </div>
            <StyledSubTitle>
              לא מצאת את התשובה שלך כאן? פשוט שלח לנו הודעה בכל שאלה.
            </StyledSubTitle>
            <StyledButton>צור קשר</StyledButton>
          </StyledContainerQuestion>
        </Grid>
        <Grid item lg={7} className="ms-auto">
          <div className="accordion">
            {faqItems.map((item, index) => (
              <Accordion key={index}>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <StyledAccordionSummaryText>
                    {item.question}
                  </StyledAccordionSummaryText>
                </StyledAccordionSummary>
                <AccordionDetails>
                  <StyledAccordionDetailsAnswer>
                    {item.answer}
                  </StyledAccordionDetailsAnswer>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuestionsAndAnswers;
