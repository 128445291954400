import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
const MailVerificationPage: React.FC = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] =
    useState<string>("מאמת...");
  console.log("token", token);
  useEffect(() => {
    // Make a request to your server to verify the token
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/verify/${token}`)
      .then((response) => {
        if (response.status === 200) {
          setVerificationStatus("האימייל אומת בהצלחה!");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          setVerificationStatus("אימות האימייל נכשל.");
        }
      })
      .catch((error) => {
        console.error("שגיאה באימות האימייל:", error);
        setVerificationStatus("אימות האימייל נכשל.");
      });
  }, [token]);

  return (
    <div>
      <h2>אימות האימייל</h2>
      <p>{verificationStatus}</p>
    </div>
  );
};

export default MailVerificationPage;
