import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ShareIcon from "@mui/icons-material/Share";
import DownloadIcon from "@mui/icons-material/Download";
import { Grid } from "@mui/material";
import SocialShareModal from "../../components/socialShareModal/SocialShareModal";
import download from "js-file-download"; // Import js-file-download
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import axios from "axios";

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

// Dialog title with larger font and subtle bottom border
const StyledDialogTitle = styled(DialogTitle)`
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
`;

// Dialog content with better line-height for readability
const StyledDialogContent = styled(DialogContent)`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
`;

// Dialog content text with slightly muted color
const StyledDialogContentText = styled(DialogContentText)`
  color: #666;
`;

// Dialog actions with spaced buttons
const StyledDialogActions = styled(DialogActions)`
  padding: 20px;
  justify-content: space-between;
`;

// Styled button with primary color and hover effect
const StyledButton = styled(Button)`
  background-color: #007bff;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #0056b3;
  }
`;
const GalleryContainer = styled.div`
  padding-top: 40px;
  text-align: center;

  background-color: #f6fcff;
  height: 100vh;
`;
const ActionButton = styled.button`
  margin: 10px;
  padding: 8px 15px;
  background-color: transparent;
  color: black;
  border: 1px solid black; // Green border

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #8017ef; // Darker green on hover
    color: white;
    border: 1px solid white; // Green border
  }

  svg {
    margin-right: 5px; // Space between icon and text
  }
`;
const Title = styled.h1`
  font-size: 32px;
  font-weight: bold;
  margin: 0;
  color: #333; /* Change the title text color */
`;

const StyledVideo = styled.video`
  width: 100%; // Responsive width
  max-width: 320px; // Maximum width
  height: auto; // Maintain aspect ratio
  border-radius: 10px; // Rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Box shadow for depth
  // Add more styles as needed
`;
const DownloadButton = styled.button`
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;
export default function HistoryVideos(iduser: any) {
  const [videos, setVideos] = useState([]);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  console.log(iduser);

  const fetchTralationg = async (newOutputId: any) => {
    const baseURL = process.env.REACT_APP_API_URL; // Ensure the base URL is set in your environment
    try {
      const getTranslation = await axios.get(
        `${baseURL}/upload/outputs/${newOutputId}/contents/`,
        { params: { userId: iduser.iduser } }
      );
      console.log(getTranslation);
    } catch (error) {
      console.error("Error fetching translation:", error);
    }
  };
  console.log(videoUrl);

  useEffect(() => {
    fetchUserVideos();
    const newOutputId = localStorage.getItem("newOutputId");
    if (newOutputId && iduser.iduser) {
      fetchTralationg(newOutputId);
      setIsDialogOpen(true); // Open the dialog
    }
  }, [iduser.iduser]);

  console.log(videoUrl);
  console.log(iduser.iduser);

  const fetchUserVideos = async () => {
    try {
      const baseURL = process.env.REACT_APP_API_URL; // Ensure the base URL is set in your environment
      const response = await axios.get(`${baseURL}/user/user/${iduser.iduser}`);

      // Assuming the response contains an array of videos
      setVideos(response.data.videoUpload);
      localStorage.removeItem("newOutputId");
    } catch (error) {
      console.error("Error fetching videos:", error);
      // Handle error (e.g., show notification to the user)
    }
  };

  const handleDownloadClick = (videoUrl: string) => {
    // Use js-file-download to download the video
    axios({
      url: videoUrl,
      method: "GET",
      responseType: "blob", // Ensure response type is blob
    }).then((response) => {
      download(response.data, "video.mp4"); // Download the blob as "video.mp4"
    });
  };

  return (
    <div
      style={{
        backgroundColor: "#f6fcff",
      }}
    >
      <Container maxWidth="lg" style={{}}>
        {" "}
        {/* Container added for centralized alignment */}
        <GalleryContainer>
          <Title>
            <h1>הסרטונים שלי</h1>
          </Title>

          <Grid container spacing={3}>
            {" "}
            {/* Grid container for responsive layout */}
            {videos?.map((video, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <StyledVideo controls muted src={video}>
                  Your browser does not support the video tag.
                </StyledVideo>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <a href={video} download target={"_blank"}>
                    <ActionButton onClick={() => handleDownloadClick(video)}>
                      <DownloadIcon />
                      הורדה
                    </ActionButton>
                  </a>
                  <ActionButton
                    onClick={() => {
                      setSelectedVideoUrl(video); // Set the URL of the video to be shared
                      setShareModalOpen(true); // Open the share modal
                    }}
                  >
                    <ShareIcon /> שיתוף
                  </ActionButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </GalleryContainer>
        <SocialShareModal
          isOpen={isShareModalOpen}
          handleClose={() => setShareModalOpen(false)}
          videoUrl={selectedVideoUrl}
        />
      </Container>
      <StyledDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDialogTitle id="alert-dialog-title">
          {"תרגום סרטון"}
        </StyledDialogTitle>
        <StyledDialogContent>
          <StyledDialogContentText id="alert-dialog-description">
            אנו שמחים לעדכן שהסרטון שהעלית נמצא כעת בתהליך תרגום מתקדם...
          </StyledDialogContentText>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton onClick={() => setIsDialogOpen(false)} color="primary">
            סגור
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </div>
  );
}
