import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

// Define a type for the component's props
type LoginPageProps = {
  iduser: string; // or the appropriate type for iduser
};

const LoginPage: React.FC<LoginPageProps> = ({ iduser }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false); // Specify type for useState
  const location = useLocation();
  const videoUpload = Cookies.get("idmovie");
  console.log(videoUpload);
  const userId = localStorage.getItem("id");
  const addVideoToUser = async () => {
    if (videoUpload && userId) {
      setIsLoading(true);
      try {
        const baseURL = process.env.REACT_APP_API_URL;
        const videoUp = await axios.put(`${baseURL}/user/video/${userId}`, {
          videoUpload: videoUpload,
        });
        if (videoUp) {
          navigate("/UserPortal/historyvideos");
        }
      } catch (error) {
        console.error("Error fetching translation:", error);
        // Handle the error in the UI as needed
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    addVideoToUser();
  }, [videoUpload]);

  return <div>{isLoading ? <CircularProgress /> : null}</div>;
};

export default LoginPage;
