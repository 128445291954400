import styled from "styled-components";
import cover from "../../assets/image/cover.jpeg";
import coverjpg from "../../assets/image/cover.jpg";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography } from "@mui/material";

import {
  Card as MuiCard,
  Grid as MuiGrid,
  Container as MuiContainer,
} from "@mui/material";
import main from "../../assets/image/Without-BG.gif";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
export const HomeSection = styled.section``;
export const HomeContainer = styled.div`
  display: flex;

  background-image: linear-gradient(
    0deg,
    rgba(248, 253, 255, 0) 0%,
    rgba(241, 252, 255, 0.51782) 48.22%,
    #f6fdff 100%
  );
  width: 100%;


  @media screen and (max-width: 500px) {
    height: 70vh;
  }
`;

export const HomeCover = styled.div`
  width: 100%;
  z-index: 0;
  background-image: url(${main});
  background-size: 76%;
  background-position: -59% 30%;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;

  z-index: 0;
  &::before {
    content: ""; // Essential for ::before
    position: absolute;
    width: 70%;
    height: 120%;
    right: -294px;
    top: -385px;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 6px 73px 62px rgba(0, 12, 119, 0.03);
    z-index: -1000;
  }

  @media screen and (max-width: 1000px) {
    background-size: 54%;
    background-position: -8% 9%;
    &::before {
      content: ""; // Essential for ::before
      position: absolute;
      width: 100%;
      height: 120%;
      right: -405px;
      top: -385px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 6px 73px 62px rgba(0, 12, 119, 0.03);
      z-index: -1000;
    }
    &::before {
      content: ""; // Essential for ::before
      position: absolute;
      width: 0%;
      height: 80%;
      right: -205px;
      top: -50px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 6px 73px 62px rgba(0, 12, 119, 0.03);
      z-index: -1000;
    }
  }

  @media screen and (max-width: 750px) {
    background-size: 80%;
    background-position: 50% 100%;

    &::before {
      content: ""; // Essential for ::before
      position: absolute;
      width: 0%;
      height: 80%;
      right: -205px;
      top: -50px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 6px 73px 62px rgba(0, 12, 119, 0.03);
      z-index: -1000;
    }
  }

  @media screen and (max-width: 500px) {
    background-size: 550px;
    background-position: 60% 55%;

    &::before {
      content: ""; // Essential for ::before
      position: absolute;
      width: 0%;
      height: 80%;
      right: -205px;
      top: -50px;
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 6px 73px 62px rgba(0, 12, 119, 0.03);
      z-index: -1000;
    }
  }
`;

export const HomeCoverText = styled.div`
  direction: ltr !important;
  position: absolute;
  top: 16%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h1 {
    font-family: "Rubik", sans-serif;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 5em;
    color: #760be0;
    text-align: center;
    margin-bottom: 2rem;
  }
  span {
    font-family: "Rubik", sans-serif;
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 1.9em;
  }
  @media screen and (max-width: 1000px) {
    direction: ltr !important;
    position: absolute;
    top: 0%;
    right: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @media screen and (max-width: 500px) {
    align-items: center;
    h1 {
      font-family: "Rubik", sans-serif;
      letter-spacing: 2px;
      font-weight: 400;
      font-size: 3em;
      color: #760be0;
      text-align: center;
      margin-bottom: 2rem;
    }
    span {
      font-family: "Rubik", sans-serif;
      letter-spacing: 2px;
      font-weight: 300;
      font-size: 1.4em;
    }
  }
`;
export const WrapperButtenContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row-reverse;

  justify-content: flex;
  margin-top: 2rem;
`;
export const WrapperButten = styled.div`

display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 2rem;
    margin-bottom: 1.6rem;


    button {
      margin-right:3rem;
      
    button background-color: initial;
    background-image: linear-gradient(#8614f8 0, #760be0 100%);
    border-radius: 5px;
    border-style: none;
    color: #fff;
    box-shadow: rgba(245, 244, 247, .25) 0 1px 1px inset;
    cursor: pointer;
    display: inline-block;
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    margin-left: -4px;
    outline: 0;
    text-align: center;
    transition: all .3s cubic-bezier(.05, .03, .35, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: bottom;
    width: 190px;
  }
  button:hover {
    opacity: .7;
  }
  @media screen and (max-width: 1000px) {
    .button-69 {
      font-size: 14px;
      height: 55px;
      line-height: 55px;
      width: 150px;
    }
  }
    `;
export const PlayButtonContainer = styled.div`
  position: absolute;

  bottom: 0px;
`;
/* CSS */
export const OurServicesContainer = styled.section`
  @media screen and (max-width: 700px) {
    padding-top: 9rem;
  }
`;

export const OurServicesContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: -5rem;
  margin-bottom: 5rem;

  h1 {
    font-family: "Rubik", sans-serif;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 3em;
    color: #760be0;
    text-align: center;
    margin-bottom: 2rem;
  }
  span {
    font-family: "Rubik", sans-serif;
    letter-spacing: 2px;
    font-weight: 300;
    font-size: 1.9em;
  }
`;
export const OurServicesContainerP = styled.p`
  line-height: 1.5;
  font-family: "Rubik", sans-serif;
  letter-spacing: 2px;
  font-weight: 300;
  font-size: 1.9em;
  text-align: center;
  margin-bottom: 2rem;
`;
export const OurServicesContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: -5rem;
  margin-bottom: 5rem;
`;
export const HowItsWorkContainer = styled.section`
  display: flex;
  width: 100%;
  background-image: linear-gradient(
    180deg,
    rgba(248, 253, 255, 0) 0%,
    rgba(241, 252, 255, 0.51782) 48.22%,
    #f6fdff 100%
  );
`;
export const HowItsWorkContainerTitle = styled.h1`
  font-family: "Rubik", sans-serif;
  letter-spacing: 2px;

  font-weight: 400;

  color: #760be0;
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    font-family: "Rubik", sans-serif;
    letter-spacing: 2px;
    font-weight: 400;
    font-size: 3em;
    color: #760be0;
    text-align: center;
    margin-bottom: 2rem;
  }
`;
export const StyledTimelineDot = styled(TimelineDot)`
  background-color: #4caf50;
  @media screen and (max-width: 600px) {

  &.MuiTimelineItem-root:before{

   flex:none;
    
  }
`;

export const StyledTitle = styled(Typography)`
  font-size: 20px;
  color: #333333; // Adjust the color as needed
  font-weight: bold;
  margin-bottom: 8px;
`;

export const StyledDescription = styled(Typography)`
  font-size: 16px;
  color: #666666; // Adjust the color as needed
`;
export const PriceContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;

  margin-bottom: 5rem;
`;

export const StyledContainer = styled(MuiContainer)`
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const StyledGrid = styled(MuiGrid)`
  display: flex;
  justify-content: center;
`;

export const StyledCard = styled(MuiCard)`
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 100%;
  width: 450px;
  @media screen and (max-width: 700px) {
    width: 300px;
  }
  &:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  text-align: center;
  background-color: #f5f5f5;
`;

export const Plan = styled.div``;

export const PlanTitle = styled.h2`
  color: #333;
  font-size: 24px;
`;

export const PlanPrice = styled.p`
  font-size: 20px;
  color: #007bff;
  font-weight: bold;
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 0.5em;

  .icon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0.5em;
  }
`;

export const OrderButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  margin-top: 15px;
  background-image: linear-gradient(#8614f8 0, #760be0 100%);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    background-color: #218838;
  }
  transition: background-color 0.3s ease;
`;

export const StyledRecommendedCaption = styled(TimelineDot)`
  position: absolute;
`;
export const StyledMainTitleQuestion = styled.div`
  font-size: 60px;
  line-height: 1.31em;
  letter-spacing: -1px;
  color: white;
  font-weight: bold;

  span {
    color: #913bff;

    text-decoration: underline;
    text-decoration-thickness: 4px;
  }
  @media (max-width: 700px) {
    font-size: 40px;
  }
`;
export const StyledSubTitle = styled.div`
  font-size: 25px;
  line-height: 1.5em;
  margin-top: 1rem;
  margin-bottom: 1rem;
  letter-spacing: -1px;
  color: white;
  font-weight: 300;
  padding-left: 8rem;
  @media (max-width: 700px) {
    padding-left: 0rem;
  }
`;
export const StyledContainerQuestion = styled.div`
  @media (max-width: 700px) {
    font-size: 40px;
    display: flex;
    padding-bottom: 25px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
export const StyledButton = styled.button`
  font-size: 17px;
  color: #fff;
  line-height: 48px;
  border-radius: 5px;
  padding: 0 40px;
  background: #8017ef;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
  cursor: pointer;
`;
export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    font-family: "gorditamedium";
    font-size: 23px;
    line-height: 1.62em;
    padding: 23px 20px 23px 35px;
    box-shadow: none;
    border-radius: 0;
    color: #010d4c;
    background: transparent;
    transition: all 0.3s ease-in-out;

    @media (max-width: 700px) {
      font-size: 16px;
    }
  }
`;

export const StyledAccordionSummaryText = styled.div`
  font-size: 20px;
  line-height: 1.62em;

  box-shadow: none;
  border-radius: 0;
  color: #010d4c;
  background: transparent;
  transition: all 0.3s ease-in-out;
  @media (max-width: 700px) {
    font-size: 16px;
  }
`;

export const StyledAccordionDetailsAnswer = styled.p`
  font-size: 18px;
  line-height: 1.62em;
  color: #010d4c;
`;

export const CricleBackground = styled.div`
  position: absolute;

  height: 900px;
  z-index: -1;
  background: radial-gradient(
    ellipse at center,

    #f9feff 50%,
    #ffffff 48%
  );
`;
