import React, { useEffect, useState } from "react";

import "./ChoosePlan.css";
import Container from "@mui/material/Container";
import axios, { AxiosResponse, AxiosError } from "axios"; // Import Axios for making API requests
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "../../redux/authSlice";
import { languages } from "../../data/languages";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import {
  StyledCard,
  FeatureList,
  FeatureItem,
  Button,
  PlanTitleTypography,
  PriceTypography,
  StyledList,
  StyledListItem,
  StyledCheckIcon,
} from "./ChoosePlan.style";

import Dialog from "@mui/material/Dialog";

import DialogContent from "@mui/material/DialogContent";
import { PriceTableData } from "../../data/home";
import IframePage from "../paymentCalls/IframePage";
import { DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckIcon from "@mui/icons-material/Check";

interface Feature {
  text: string;
  isAvailable: boolean;
}

interface PriceTable {
  title: string;
  price: string;
  whatdoyouget: Feature[];
  button: string;
  link: string;
  isFeatured?: boolean;
  planeInEnglish?: string;
  priceToSet?: number;
}

interface ChoosePlanProps {
  userId: string;
  setUserId: (userId: string) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  dialogUrl: string;
  setDialogUrl: (dialogUrl: string) => void;
  setSelectedPlan: (selectedPlan: string) => void;
  setPrice: (price: number) => void;
  setFullName: (fullName: string) => void;
  fullName: string;
  setLowProfileDealGuid: (LowProfileDealGuid: string) => void;
  price: number;
  LowProfileDealGuid: string;
  selectedPlan: string;
}
const ChoosePlan: React.FC<ChoosePlanProps> = ({
  userId,
  setUserId,
  open,
  setOpen,
  dialogUrl,
  setDialogUrl,
  setSelectedPlan,
  setPrice,
  setFullName,
  fullName,
  price,
  selectedPlan,

  setLowProfileDealGuid,
}) => {
  const [selectedProgram, setSelectedProgram] = useState(""); // State to track the selected program
  const MostPopularCard = (props: any) => (
    <Card
      {...props}
      sx={{ ...props.sx, borderColor: "primary.main", borderWidth: 2 }}
    />
  );
  const currentDate = new Date();

  const dispatch = useDispatch(); // Initialize the dispatch function
  const [lowProfileCode, setLowProfileCode] = useState(""); // State to track the user ID
  const [email, setEmail] = useState(""); // State to track the user's email
  const [languageListOpen, setLanguageListOpen] = useState(false);

  const userEmail = Cookies.get("userEmail");
  const navigate = useNavigate();

  const getEmail = useSelector((state: { auth: AuthState }) => state.auth);
  console.log(getEmail);
  useEffect(() => {
    setEmail(getEmail.user.email);
  }, [getEmail]);
  const handleLanguageListOpen = () => {
    setLanguageListOpen(true);
  };

  const handleLanguageListClose = () => {
    setLanguageListOpen(false);
  };
  const handleClickOpen = (program: string, price: number) => {
    setSelectedPlan(program); // Set the selected plan before opening the dialog
    setPrice(price);

    sendCardcomPaymentRequest(program, price);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPlan(""); // Clear the selected plan when the dialog is closed
    checkPaymentStatus(lowProfileCode);
    setPrice(0);
  };
  useEffect(() => {
    const getUserId = async () => {
      try {
        const baseURL = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `${baseURL}/user/getuser/${userEmail}`
        );
        if (response.status === 200) {
          const _id = response?.data?.user._id;
          const fullName = response?.data?.user.fullName;
          setUserId(_id);
          setFullName(fullName);
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    };
    getUserId();
  }, [userEmail]);
  const checkPaymentStatus = async (lowProfileCode: string) => {
    const low = lowProfileCode;
    console.log("low", low);
    try {
      const baseURL = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${baseURL}/pay/get-low-profile-indicator`,
        {
          params: {
            lowProfileCode: low,
          },
        }
      );

      if (response.status === 200) {
        const paymentStatus = response?.data;
        console.log("paymentStatus", paymentStatus);

        // Attempt to find ResponseCode in the HTML content
        const responseCodeMatch = /ResponseCode=(\d+)/.exec(paymentStatus);

        if (responseCodeMatch) {
          const responseCode = parseInt(responseCodeMatch[1], 10);
          console.log("responseCode", responseCode);

          if (responseCode === 0) {
            // Payment was successful
            console.log("Payment was successful");
            directDebit(selectedPlan, price, lowProfileCode);
          } else {
            // Payment was not successful
            console.log("Payment failed");
          }
        } else {
          // ResponseCode not found in HTML content
          console.log("ResponseCode not found in the response.");
        }
      }
    } catch (error) {
      console.error("Failed to fetch payment status:", error);
    }
  };

  const sendCardcomPaymentRequest = async (selectedPlan: any, price: any) => {
    try {
      const baseURL = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${baseURL}/pay/payment-request`, {
        selectedPlan,
        price,
        userEmail,
        fullName,
      });
      const { data } = response;
      console.log(data);

      if (data) {
        const responseString = data;
        const responseArray = responseString.split("&");
        let url = "";

        for (const pair of responseArray) {
          const [key, value] = pair.split("=");

          if (key === "url") {
            // Decode the URL to get the actual URL
            url = decodeURIComponent(value);
            break; // Exit the loop once 'url' is found
          }
        }

        console.log(url);
        const parsedUrl = new URL(url);

        const lowProfileCode = parsedUrl.searchParams.get("LowProfileCode");
        console.log(lowProfileCode);
        if (lowProfileCode !== null) {
          localStorage.setItem("lowProfileCode", lowProfileCode);
          setLowProfileCode(lowProfileCode);
        }
        setDialogUrl(url); // Set the URL to open in the dialog
        setOpen(true); // Open the dialog
      }
    } catch (error: any) {
      console.error("Error:", error.message);
    }
  };
  const handleProgramSelect = async (program: string) => {
    try {
      const baseURL = process.env.REACT_APP_API_URL;

      const response: AxiosResponse = await axios.put(
        `${baseURL}/user/selectprogram/${userId}`,
        {
          selectedProgram: program,
        }
      );

      if (response.status === 200) {
        console.log("Program selection successful");
        localStorage.setItem("program", program);
        // Navigate to the appropriate route, e.g., "/UserPortal"
        navigate("/UserPortal");
      } else {
        console.error("Program selection failed");
        // Handle the failure gracefully, show an error message, etc.
      }
    } catch (error: any) {
      console.error("Program selection failed:", error);
      // Handle the failure gracefully, show an error message, etc.
    }
  };
  const directDebit = async (
    selectedPlan: any,
    price: number,
    LowProfileDealGuid: string
  ) => {
    const baseURL = process.env.REACT_APP_API_URL;

    try {
      const response = await axios.get(`${baseURL}/pay/cardcom-request`, {
        params: {
          selectedPlan: selectedPlan,
          price: price,
          CardOwnerName: fullName,
          LowProfileDealGuid: LowProfileDealGuid,
        },
      });

      if (selectedPlan) {
        handleProgramSelect(selectedPlan);
      }

      console.log("res.data", response.data);
    } catch (error) {
      console.error("HTTP Request Error:", error);
    }
  };
  const goldLanguages = languages.filter(
    (language) => language.plan === "gold"
  );

  return (
    <div
      style={{
        background:
          "radial-gradient(circle, rgba(65,37,101,1) 0%, rgba(4,2,8,1) 100%)",
        color: "white",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth={"xl"}>
        <div className="title_price">
          <h1>בחר את התוכנית שלך</h1>
          <p>תבחרו את החבילה המתאימה לכם ותוכלו להתחיל להפיק עוד היום</p>
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {PriceTableData.map((plan: PriceTable, index: number) => (
            <StyledCard key={index}>
              <CardContent>
                <PlanTitleTypography variant="h5">
                  {plan.title}
                </PlanTitleTypography>
                <PriceTypography>
                  {" "}
                  {plan.price}{" "}
                  <span
                    style={{
                      fontSize: "1rem",
                      fontWeight: "normal",
                    }}
                  >
                    {" "}
                    לחודש
                  </span>{" "}
                </PriceTypography>

                <StyledList>
                  {plan.whatdoyouget.map((feature, index) => (
                    <StyledListItem key={index}>
                      <StyledCheckIcon /> {feature.text}
                    </StyledListItem>
                  ))}
                </StyledList>
              </CardContent>
              <Button
                onClick={() => {
                  if (plan.planeInEnglish === "basic") {
                    handleProgramSelect(plan.planeInEnglish);
                  } else {
                    handleClickOpen(
                      plan.planeInEnglish || "basic",
                      plan.priceToSet || 0
                    );
                  }
                }}
              >
                {plan.button}
              </Button>
            </StyledCard>
          ))}
        </Box>
        <p>
          לרשימת כל השפות לחץ{" "}
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleLanguageListOpen}
          >
            כאן
          </span>
        </p>{" "}
        {/* <Container maxWidth="lg">
      <Box py={12} textAlign="center">
        <Typography variant="h4" component="h1" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.
        </Typography>
        <Box my={4} mx="auto" width="24%" bgcolor="indigo.200" height="4px" borderRadius="20px" />

        <Grid container spacing={4} justifyContent="center">
          {pricingPlans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.title}>
              <Card variant="outlined" sx={{ minHeight: 360 }}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {plan.title}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    {plan.price}
                  </Typography>
                  <Box mt={2}>
                    {plan.features.map((feature) => (
                      <Box key={feature} display="flex" alignItems="center" mt={1}>
                        <CheckCircleOutlineIcon color="success" />
                        <Typography marginLeft={1}>{feature}</Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
                <CardActions>
                  <Button size="large" fullWidth variant={plan.mostPopular ? 'contained' : 'outlined'}>
                    Select
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container> */}
        {/* <div className="cards_price">
        <div className="card_price shadow">
          <ul className="ul_price">
            <li className="pack">בסיסית</li>
            <li id="professional" className="price bottom-bar">
              0₪
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                }}
              >
                {" "}
                לחודש
              </span>
            </li>
            <li className="bottom-bar"> סרטון אחד חינם, כל סרטון נוסף 24.90</li>
            <li className="bottom-bar">כל סרטון עד 3 דקות </li>
            <li className="bottom-bar">תרגום מעברית לאנגלית בלבד</li>
            <li className="bottom-bar"> סיכנרון שפתיים</li>

            <li>
              <button
                className="btn"
                onClick={() => {
                  handleProgramSelect("basic");
                }}
              >
                בחר
              </button>
            </li>
          </ul>
        </div>
        <div className="card active">
          <ul className="ul_price">
            <li
              className="pack "
              style={{
                fontSize: "1.8rem",
              }}
            >
              סילבר
            </li>
            <li id="professional" className="price bottom-bar">
              159₪
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                }}
              >
                {" "}
                לחודש
              </span>
            </li>
            <li className="bottom-bar"> 15 סרטונים בחודש</li>
            <li className="bottom-bar">כל סרטון עד 4 דקות </li>
            <li className="bottom-bar">תרגום מעברית לאנגלית/ערבית/צרפתית</li>
            <li className="bottom-bar"> סיכנרון שפתיים</li>
            <li>
              <button
                className="btn active-btn "
                onClick={() => {
                  handleClickOpen("silver", 159);
                }}
                // onClick={() => {
                //   handleProgramSelect("silver");
                // }}
              >
                בחר
              </button>
            </li>
          </ul>
        </div>
        <div className="card shadow">
          <ul className="ul_price">
            <li className="pack">זהב</li>
            <li id="professional" className="price bottom-bar">
              229₪
              <span
                style={{
                  fontSize: "1rem",
                  fontWeight: "normal",
                }}
              >
                {" "}
                לחודש
              </span>
            </li>
            <li className="bottom-bar"> 30 סרטונים בחודש</li>
            <li className="bottom-bar">כל סרטון עד 5 דקות </li>{" "}
            <li className="bottom-bar"> תרגום לכל השפות *</li>
            <li className="bottom-bar"> סיכנרון שפתיים</li>
            <li>
              <button
                className="btn"
                onClick={() => {
                  handleClickOpen("gold", 229);
                }}
              >
                בחר
              </button>
            </li>
        
          </ul>
        </div>
      </div> */}
        <Dialog
          open={languageListOpen}
          onClose={handleLanguageListClose}
          aria-labelledby="language-list-dialog-title"
        >
          <DialogTitle id="language-list-dialog-title">רשימת שפות</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <ul className="language-list">
                {goldLanguages.map((language) => (
                  <li key={language.code}>
                    <img src={language.img} alt={language.label} />{" "}
                    {language.label}
                  </li>
                ))}
              </ul>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleLanguageListClose}
              style={{
                backgroundColor: "#760BE0",
                color: "white",
              }}
            >
              סגור
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            {dialogUrl && <IframePage dialogUrl={dialogUrl} />}
          </DialogContent>
        </Dialog>
      </Container>
    </div>
  );
};

export default ChoosePlan;
