import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const PrivateRoute: React.FC = () => {
  debugger;
  const user = useSelector((state: RootState) => state.auth.user);
  const isAuthenticated = !!user.email;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoute;
