import React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import LogoutIcon from "@mui/icons-material/Logout";
import { clearUserData } from "../../redux/authSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const NavbarPortalProfile: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // Dispatch the clearUserData action
    dispatch(clearUserData());

    // If you need to remove additional cookies or perform other logout operations, do so here
    // For example:

    Cookies.remove("otherUserData");
    navigate("/signin");

    // Redirect to login page or do other cleanup tasks
    console.log("User logged out, perform additional cleanup if needed");
  };
  // Here we specify the component as a React Functional Component
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        height: "100%",
        borderLeft: "1px solid #ffffff20",
        backgroundColor: "#1c1d21",
        ".MuiAppBar-root": {
          boxShadow: "none",
        },
        ".MuiContainer-root": {
          paddingLeft: "0 !important",
          paddingRight: "0 !important",
        },
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        elevation={0}
        sx={{
          borderBottom: "1px solid #ffffff20",
          backgroundColor: "transparent",
        }}
      >
        <Toolbar sx={{ justifyContent: "flex-start" }}>
          <Typography
            variant="h6"
            color="inherit"
            sx={{
              flexGrow: 1,
              color: "white",
              textAlign: "left",
            }}
          >
            פרופיל
          </Typography>
          <Button
            onClick={() => navigate("/userportal")}
            style={{
              backgroundColor: "transparent", // Makes the button background transparent
              padding: 0, // Removes padding around the icon
              minWidth: "auto", // Prevents the button from having a minimum width
            }}
          >
            <ArrowForwardIcon
              style={{
                color: "white",
                fontSize: "2rem",
              }}
            />
          </Button>
        </Toolbar>
      </AppBar>
      <Container>
        <List
          component="nav"
          sx={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "flex-start",
            height: "100",
          }}
        >
          <ListItem
            button
            sx={{
              marginBottom: "10px",
              backgroundColor: "#1f1f1f",
              "&:hover": {
                backgroundColor: "#ffffff20",
                ".MuiListItemIcon-root": {
                  color: "#8011f0",
                },
                ".MuiListItemText-primary": {
                  color: "#8011f0",
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary="ניהול משתמש"
              sx={{ color: "white", display: "flex" }}
            />
          </ListItem>
          <ListItem
            button
            sx={{
              backgroundColor: "#1f1f1f",
              "&:hover": {
                backgroundColor: "#ffffff20",
                ".MuiListItemIcon-root": {
                  color: "#8011f0",
                },
                ".MuiListItemText-primary": {
                  color: "#8011f0",
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText
              primary="תשלום"
              sx={{ color: "white", display: "flex" }}
            />
          </ListItem>
          <ListItem
            onClick={handleLogout}
            button
            sx={{
              marginTop: "10px",
              backgroundColor: "#1f1f1f",
              "&:hover": {
                backgroundColor: "#ffffff20",
                ".MuiListItemIcon-root": {
                  color: "#8011f0",
                },
                ".MuiListItemText-primary": {
                  color: "#8011f0",
                },
              },
            }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="התנתקות"
              sx={{ color: "white", display: "flex" }}
            />
          </ListItem>
        </List>
      </Container>
    </Box>
  );
};

export default NavbarPortalProfile;
